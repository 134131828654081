<template>
  <div class="position-relative">
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
      id="job"
      v-model="jobQuery"
      type="text"
      class="form-control"
      placeholder="Ex: vendeur"
      autocomplete="off"
      @input="lookupJob">

    <div v-if="suggestedJobs && suggestedJobs.length > 0 && jobQuery.length > 2" class="dropdown-menu show">
      <button
        v-for="job in suggestedJobs"
        :key="job.id"
        type="button" class="dropdown-item"
        @click.prevent="selectJob(job)">
        <span class="dropdown-item-name">
          {{ job.name }}
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { DebouncedFunc, throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';

import AddressRegistration from '../interfaces/address-registration';
import Job from '../interfaces/job';
import JobSuggestion from '../interfaces/job-suggestion';

export default defineComponent({
  name: 'JobField',
  props: {
    address: {
      type: Object as PropType<AddressRegistration>,
      required: true,
    },
  },
  emits: ['onSelectJob'],

  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      jobQuery: '',
      suggestedJobs: [] as JobSuggestion[],
      lookupJobHandler: throttle(this.lookupJob, 200) as DebouncedFunc<() => void>,
    };
  },
  watch: {
    jobs(): void {
      // this.initializeQuery();
    },
  },
  mounted(): void {
    this.lookupJob();
  },
  methods: {
    // Search an activity sector by name
    async lookupJob(): Promise<void> {
      if (this.jobQuery.length < 3) {
        return;
      }

      const data = await window.ky.get(`/api/jobs/search/?term=${this.jobQuery}`).json();
      this.suggestedJobs = data.hits;
    },
    selectJob(job: Job): void {
      this.$emit('onSelectJob', job);
      this.suggestedJobs = [];
      this.jobQuery = '';
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.form-group {
  position: relative;
}

.dropdown-menu {
  background-color: $white;
  max-height: 134px;
  overflow-y: auto;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: 100%;
}

.dropdown-item-name {
  display: inline-flex;
  padding: 0 3px;
}
</style>
