<template>
  <form class="account-form" @submit.prevent="saveAccount()">
    <div class="form-group form-group-required">
      <label class="form-label" for="company">{{ $t('components.account_form.company') }}</label>
      <input
        id="company"
        v-model="account.recruiter.company"
        type="text"
        class="form-control"
        name="company"
        :class="{ 'is-invalid': account.recruiter.company && v.account.recruiter.company.$invalid }">
      <div
        v-if="account.recruiter.company && !v.account.recruiter.company.required"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>
    </div>

    <div class="form-group form-group-required">
      <label class="form-label" :for="'address_content'">{{ $t('components.register_information.address.label') }}</label>
      <VueBootstrapAutocomplete
        id="address_content"
        v-model="addressQuery"
        :max-matches="5"
        class="mb-1"
        :show-all-results="true"
        :data="mapboxAddresses"
        :serializer="item => item.place_name"
        placeholder=""
        @hit="onSelectAddress($event)"
        @input="lookupAddressHandler"
      />
      <small class="form-text text-muted">{{ $t('form.helper.address') }}</small>
      <small class="text-black-50">{{ $t('components.account_form.address_helper') }}</small>
    </div>

    <div class="form-group form-group-required">
      <label class="form-label" for="company">{{ $t('components.register_information.manager.label') }}</label>
      <input
        id="manager"
        v-model="account.recruiter.manager"
        type="text"
        class="form-control"
        name="manager"
        :class="{ 'is-invalid': account.recruiter.manager && v.account.recruiter.manager.$invalid }">
      <div
        v-if="account.recruiter.manager && !v.account.recruiter.manager.required"
        data-cy="account-manager-required-invalid-feedback"
        class="invalid-feedback">
        {{ $t('form.validation.required') }}
      </div>

      <div
        v-if="account.recruiter.manager && !v.account.recruiter.manager.hasFullName"
        data-cy="account-manager-full-name-invalid-feedback"
        class="invalid-feedback">
        {{ $t('form.validation.full_name') }}
      </div>
    </div>

    <div class="form-group">
      <label for="uploadFile" class="form-label d-block">
        <div>{{ $t('components.manage_address.label.logo') }}</div>
        <v-file-indicator :file="selectedFile">
          <div class="mt-2">
            <small class="form-text steel-text">
              {{ $t('form.image.square') }} <br>
              {{ $t('form.image.format') }} <br>
              {{ $t('form.image.max_size') }}
            </small>
          </div>
        </v-file-indicator>
      </label>
      <input id="uploadFile" type="file" class="form-control visually-hidden" @change="onFileChange($event)">
    </div>

    <div class="form-group">
      <button id="account-form-btn" type="submit" class="btn btn-primary btn-rounded" :disabled="v.account.$invalid">
        {{ $t('actions.save') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  cloneDeep, throttle,
} from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import VueBootstrapAutocomplete from 'vue3-bootstrap-autocomplete';
import { mapActions, mapState } from 'vuex';

import Address from '@/js/interfaces/address';

import { HTMLInputEvent } from '../interfaces/events';
import MapboxAddress from '../interfaces/mapbox-address';
import { searchMapboxAddress } from '../services/mapbox';
import { hasFullName } from '../services/utils';

interface Account {
  recruiter: {
    company: string,
    manager: string,
    address: MapboxAddress,
  },
}

export default defineComponent({
  name: 'AccountForm',
  components: { VueBootstrapAutocomplete },
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      account: {
        recruiter: {
          company: '',
          manager: '',
          address: {} as MapboxAddress,
        },
      } as Account,
      mapboxAddress: {} as MapboxAddress,
      mapboxAddresses: [] as MapboxAddress[],
      addressQuery: '',
      selectedFile: '',
      logo: '',
    };
  },
  validations() {
    return {
      account: {
        recruiter: {
          company: { required },
          manager: { required, hasFullName },
          address: { required },
        },
      },
    };
  },
  computed: {
    ...mapState('recruiter', [
      'user',
      'parentAddress',
    ]),
  },
  mounted() {
    this.account = {
      recruiter: {
        company: this.user.recruiter.company,
        manager: this.user.recruiter.manager,
        address: this.user.recruiter.address,
      },
    };
    this.addressQuery = this.account.recruiter.address?.placeName ?? '';
  },
  created() {
    this.lookupAddressHandler = throttle(this.searchAddress, 1000);
  },
  unmounted() {
    this.lookupAddressHandler.cancel();
  },
  methods: {
    ...mapActions('recruiter', [
      'setUser',
      'getProfile',
      'getAddresses',
      'setCurrentAddress',
    ]),
    async searchAddress(): Promise<void> {
      if (this.addressQuery && this.addressQuery.length > 2) {
        this.mapboxAddress = {} as MapboxAddress;
        this.account.recruiter.address = this.mapboxAddress;
        const data = await searchMapboxAddress(this.addressQuery);
        this.mapboxAddresses = data.suggestions;
      } else {
        this.mapboxAddresses = [];
      }
    },
    onSelectAddress(selectedAddress: MapboxAddress): void {
      this.addressQuery = selectedAddress.place_name;
      this.mapboxAddress = selectedAddress;
      this.mapboxAddresses = [];
      this.account.recruiter.address = this.mapboxAddress;
    },
    async updateLogo() :Promise<void> {
      // Update logo if necessary
      if (this.logo && this.logo.startsWith('data:image/')) {
        const payload = {
          logo: this.logo,
        };

        let addressUuid;

        if (this.user.recruiter.premium && this.parentAddress) {
        // Update parent address
          addressUuid = this.parentAddress.uuid;
        } else {
        // Update first address
          const result = await this.getAddresses({ page: 1 });
          if (result && result.results) {
            addressUuid = result.results[0].uuid;
          }
        }

        if (addressUuid) {
          const data = await window.ky.patch(`/api/addresses/${addressUuid}/`, { json: payload }).json<Address>();
          await this.setCurrentAddress(data);
        }
      }
    },
    async saveAccount():Promise<void> {
      try {
        const data = await window.ky.patch('/api/recruiter/me/', { json: this.account }).json<Recruiter>();
        await this.getProfile();

        const user = cloneDeep(this.user);
        user.recruiter.manager = data.recruiter.manager;
        user.recruiter.address = data.recruiter.address;
        user.recruiter.company = data.recruiter.company;

        await this.setUser(user);
        this.toast.success((this.$t('components.account_form.success') as string));
        await this.updateLogo();
      } catch (error) {
        console.error(error);
        this.toast.error((this.$t('components.account_form.error') as string));
      }
    },
    onFileChange(e: HTMLInputEvent | DragEvent):void {
      const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
      if (!files?.length) return;
      this.selectedFile = files[0].name;
      this.createImage(files[0]);
    },
    createImage(file: Blob):void {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.logo = (reader.result as string);
      });
      reader.readAsDataURL(file);
    },
  },
});
</script>
