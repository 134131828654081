<template>
  <div class="contact-us-card">
    <div>
      <h3 class="contact-us-title">{{ $t('views.register_step_1.subscription_multiple_accounts') }}</h3>
      <p class="contact-us-franchises">{{ $t('views.register_step_1.subscription_franchises') }}</p>
    </div>

    <a href="/#contact" class="btn btn-primary">{{ $t('views.register_step_1.subscription_contact_us') }}</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContactUsCard',
});
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../../scss/config/config';

.contact-us-card {
  background-color: $white;
  border-radius: math.div($grid-gutter-width, 2);
  box-shadow: 0 0 math.div($grid-gutter-width, 2) 0 rgba(0, 0, 0, .15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 56px 20px 40px;
}

.contact-us-title {
  font-size: 22px;
  font-weight: 300;
}

.contact-us-franchises {
  color: $gray-900;
  font-size: 26px;
  font-weight: 500;
  line-height: $headings-line-height;
}
</style>
