import '@glidejs/glide/dist/glide.min';

import Glide from '@glidejs/glide';

window.addEventListener('load', () => {
  if (document.querySelector('.glide')) {
    new Glide('.glide', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      rewind: true,
    }).mount();
  }

  if (document.querySelector('.glide2')) {
    const slide = new Glide('.glide2', {
      type: 'carousel',
      perView: 4,
      focusAt: 'center',
      autoplay: 3000,
      breakpoints: {
        800: {
          perView: 2,
        },
        480: {
          perView: 1,
        },
      },
    }).mount();

    const controlLeft = document.querySelector('#homepage-visible-controls-left');
    const controlRight = document.querySelector('#homepage-visible-controls-right');

    if (controlLeft) {
      controlLeft.addEventListener('click', () => slide.go('<'));
    }

    if (controlRight) {
      controlRight.addEventListener('click', () => slide.go('>'));
    }
  }
});
