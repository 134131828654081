<template>
  <header class="register-header">
    <Logo :color="true" />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Logo from '@/js/components/Logo.vue';

export default defineComponent({
  name: 'RegisterHeader',
  components: { Logo },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

</style>
