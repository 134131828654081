<template>
  <div class="brand-values">
    <p class="brand-values-description steel-text">
      {{ $t('components.brand_values.description') }}
    </p>

    <div class="row">
      <div class="col-sm-6">
        <form action="">
          <div class="form-group">
            <label for="values" class="form-label">
              {{ $t('components.brand_values.label') }}
            </label>

            <textarea
              id="values"
              v-model="localValues"
              :placeholder="$t('components.brand_values.placeholder')"
              name="values"
              cols="30"
              rows="10"
              class="form-control values-text"
              maxlength="2000"
              @change="setLocalValues(($event.target as HTMLInputElement).value)"
            />

            <p class="hint steel-text">{{ remainingChars }} {{ $t('components.brand_values.hint') }}</p>
          </div>
        </form>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary rounded"
      :disabled="!isUpdated"
      @click.prevent="confirmSaveModalOpened = true">
      {{ $t('actions.save') }}
    </button>

    <v-modal id="saveValuesModal" v-model="confirmSaveModalOpened">
      <div class="p-2">
        <h5 class="text-center mb-4">{{ $t('views.brand.save_modal.title') }}</h5>
        <p class="text-center mb-4 steel-text">{{ $t('views.brand.save_modal.description') }}</p>
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-light m-2 cancel-logout-btn" @click.prevent="confirmSaveModalOpened = false">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-primary m-2" @click.prevent="save()">
            {{ $t('actions.save') }}
          </button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'BrandValues',
  props: {},
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      confirmSaveModalOpened: false,
      localValues: '',
    };
  },
  computed: {
    ...mapState('brand', [
      'values',
      'isUpdated',
    ]),
    ...mapState('recruiter', [
      'currentAddress',
    ]),
    remainingChars(): number {
      return 2000 - this.localValues.length;
    },
  },
  mounted() {
    if (this.currentAddress.values) {
      this.setValues(this.currentAddress.values);
      this.localValues = this.currentAddress.values;
    }
  },
  methods: {
    ...mapActions('brand', [
      'setValues',
      'saveValues',
      'setIsUpdated',
    ]),
    ...mapActions('recruiter', [
      'setCurrentAddress',
    ]),
    setLocalValues(values: string): void {
      this.setIsUpdated(true);
      this.setValues(values);
    },
    async save(): Promise<void> {
      try {
        await this.saveValues();
        this.setCurrentAddress(this.currentAddress);
        this.confirmSaveModalOpened = false;
        this.toast.success((this.$t('components.brand_values.save.success') as string));
      } catch {
        this.toast.error((this.$t('components.brand_values.save.error') as string));
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-values-description {
  font-size: 14px;
}

.form-label {
  color: $primary;
  font-size: 18px;
  font-weight: 700;
}

.hint {
  font-size: 12px;
  margin-top: .5rem;
  text-align: right;
}

.values-text {
  border-color: $pale-lavender;
  border-radius: 6pt;
}

</style>
