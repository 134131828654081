import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {
  const registerButton = document.querySelector('#hero-signup-btn');

  const signUpButton = document.querySelectorAll('.sign-up-btn');
  const myAccountButton = document.querySelectorAll('.my-account-btn');
  const myAccountButtonMobile = document.querySelectorAll('.my-account-btn-mobile');
  const logInButton = document.querySelector('#header-login-btn');
  const logInButtonMobile = document.querySelector('#header-login-btn-mobile');
  const offCanvasElement = document.querySelector('#offcanvasNav');

  if (offCanvasElement) {
    const offCanvasNav = new Offcanvas(offCanvasElement);

    const offCanvasLinks = Array.prototype.slice.call(document.querySelectorAll('#offcanvasNav .topbar-actions a'));
    for (const element of offCanvasLinks) {
      element.addEventListener('click', () => {
        offCanvasNav.hide();
      });
    }
  }

  const displayLoggedInButtons = () => {
    for (const button of myAccountButton) {
      button.classList.remove('d-none');
    }
    for (const button of myAccountButtonMobile) {
      button.classList.remove('d-none');
    }
  };

  const displayDisconnectedButtons = () => {
    registerButton?.classList.remove('d-none');
    logInButton?.classList.remove('d-none');
    logInButtonMobile?.classList.remove('d-none');

    for (const button of signUpButton) {
      button.classList.remove('d-none');
    }
  };

  const isLoggedIn = !!Cookies.get('jdmcv_access');

  if (isLoggedIn) {
    displayLoggedInButtons();
  } else {
    displayDisconnectedButtons();
  }
});
