<template>
  <div class="profile-header mb-2">
    <div class="application-job d-flex align-items-center">
      <span v-if="job" class="application-job-title">{{ $t('views.resume.job_selected') }}&nbsp;: </span>
      <span v-if="job" class="application-job-name mx-2 text-xs text-primary">{{ job.name }}</span>
    </div>
    <router-link :to="{ name: routeNames.resumes }" class="return-to-resumes text-white">
      <img class="return-icon" src="@/images/back.svg" alt="">
      <span class="d-inline d-sm-none">{{ $t('views.resume.return_to_resumes_mobile') }}</span>
      <span class="d-none d-sm-inline">{{ $t('views.resume.return_to_resumes') }}</span>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import Job from '@/js/interfaces/job';
import routesNames from '@/js/router/route-names';

export default defineComponent({
  name: 'ProfileHeader',
  props: {
    job: {
      type: Object as PropType<Job | null>,
      required: true,
    },
  },
  computed: {
    routeNames() {
      return routesNames;
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($from: tablet) {
    align-items: center;
    flex-direction: row;
  }

  @media print {
    display: none;
  }

  .application-job {
    font-size: 13px;
    margin-bottom: 1rem;
    order: 2;

    @include mq($from: tablet) {
      font-size: 14px;
      margin-bottom: 0;
      order: 1;
    }
  }

  .application-job-title {
    white-space: nowrap;
  }

  .return-to-resumes {
    font-weight: 700;
    margin-bottom: 1rem;
    order: 1;

    @include mq($from: tablet) {
      margin-bottom: 0;
      order: 2;
      text-align: right;
    }

    .return-icon {
      margin: 0 10px;
    }
  }
}

.application-job-name {
  background-color: $light;
  border-radius: 10px;
  margin-left: 10px;
  padding: 8px 15px;
}
</style>
