<template>
  <div class="brand-media">
    <p class="brand-media-description steel-text">{{ $t('components.brand_media.description') }}</p>

    <div class="row">
      <div class="col-sm-6">
        <form action="">
          <div class="form-group position-relative">
            <label for="youtube_link" class="form-label">{{ $t('components.brand_media.youtube_link') }}</label>
            <input
              id="youtube_link"
              v-model="localYoutubeLink"
              type="url"
              name="youtube_link"
              class="form-control video-input"
              :placeholder="$t('components.brand_media.youtube_link_placeholder')"
              :class="{ 'is-invalid': v.localYoutubeLink.$invalid }"
              @change="setLocalYoutubeLink($event.target.value)">
            <div class="position-absolute video-link-icon btn-rounded">
              <v-sprite icon="enter" />
            </div>
            <div v-if="v.localYoutubeLink.$invalid" class="invalid-feedback">
              {{ $t('form.validation.youtube_link') }}
            </div>
          </div>

          <div v-if="youtubeIframe">
            <div class="position-relative">
              <div class="address-video ratio ratio-16x9" v-html="youtubeIframe" />
              <button type="button" class="address-video-delete" @click.prevent="deleteVideo()">
                <v-sprite icon="delete" />
              </button>
            </div>
          </div>

          <div class="form-group">
            <label for="youtube_link" class="form-label">
              {{ $t('components.brand_media.pictures') }}
              <span class="text-secondary">{{ $t('components.brand_media.pictures_hint') }}</span>
            </label>

            <div class="dropzone-list">
              <div
                class="dropzone"
                @drop.prevent="dropPicture($event)"
                @dragenter="checkDrop"
                @dragover="checkDrop"
              >
                <label for="picture" class="dropzone-label">
                  <img v-if="picture" :src="picture" alt="" class="img-fluid">
                  <v-sprite v-if="!picture" icon="upload" />
                </label>
                <input
                  id="picture"
                  ref="file"
                  class="dropzone-input"
                  type="file"
                  name="picture"
                  accept=".jpg,.jpeg,.png"
                  @change="onPictureChange($event as HTMLInputEvent, 0)"
                >
                <button
                  v-if="picture"
                  type="button"
                  class="dropzone-delete"
                  @click.prevent="deletePicture()">
                  <v-sprite icon="delete" />
                </button>
              </div>

              <div
                class="dropzone"
                @drop.prevent="dropPictureTwo($event)"
                @dragenter="checkDrop"
                @dragover="checkDrop"
              >
                <label for="pictureTwo" class="dropzone-label">
                  <img v-if="pictureTwo" :src="pictureTwo" alt="" class="img-fluid">
                  <v-sprite v-if="!pictureTwo" icon="upload" />
                </label>
                <input
                  id="pictureTwo"
                  ref="file"
                  class="dropzone-input"
                  type="file"
                  name="pictureTwo"
                  accept=".jpg,.jpeg,.png"
                  @change="onPictureChange($event as HTMLInputEvent, 1)"
                >
                <button
                  v-if="pictureTwo"
                  type="button"
                  class="dropzone-delete"
                  @click.prevent="deletePictureTwo()">
                  <v-sprite icon="delete" />
                </button>
              </div>

              <div
                class="dropzone"
                @drop.prevent="dropPictureThree($event)"
                @dragenter="checkDrop"
                @dragover="checkDrop"
              >
                <label for="pictureThree" class="dropzone-label">
                  <img v-if="pictureThree" :src="pictureThree" alt="" class="img-fluid">
                  <v-sprite v-if="!pictureThree" icon="upload" />
                </label>
                <input
                  id="pictureThree"
                  ref="file"
                  class="dropzone-input"
                  type="file"
                  name="pictureThree"
                  accept=".jpg,.jpeg,.png"
                  @change="onPictureChange($event as HTMLInputEvent, 2)"
                >
                <button
                  v-if="pictureThree"
                  type="button"
                  class="dropzone-delete"
                  @click.prevent="deletePictureThree()">
                  <v-sprite icon="delete" />
                </button>
              </div>
            </div>
            <small class="form-text steel-text">
              {{ $t('components.brand_media.image.format') }} <br> {{ $t('components.brand_media.image.max_size') }}
            </small>
          </div>
        </form>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary btn-rounded btn-small"
      :disabled="!isUpdated || v.localYoutubeLink.$invalid"
      @click.prevent="confirmSaveModalOpened = true">
      {{ $t('actions.save') }}
    </button>

    <v-modal id="saveMediaModal" v-model="confirmSaveModalOpened">
      <div class="p-2">
        <h5 class="text-center mb-4">{{ $t('views.brand.save_modal.title') }}</h5>
        <p class="text-center mb-4 steel-text">{{ $t('views.brand.confirm_modal.description') }}</p>
        <div class="d-flex justify-content-center align-items-center">
          <button
            type="button"
            class="btn btn-outline-primary btn-rounded m-2 cancel-logout-btn" @click.prevent="confirmSaveModalOpened = false">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-primary m-2 btn-rounded" @click.prevent="save()">
            {{ $t('actions.save') }}
          </button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { HTMLInputEvent } from '../interfaces/events';

export default defineComponent({
  name: 'BrandMedia',
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      youtubeUrlRegex: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
      confirmSaveModalOpened: false,
      localYoutubeLink: null as (string | null),
      localPictures: [null, null, null] as (string[] | null[]),
    };
  },
  computed: {
    ...mapState('brand', [
      'currentTab',
      'picture',
      'pictureTwo',
      'pictureThree',
      'isUpdated',
    ]),
    ...mapState('recruiter', [
      'currentAddress',
    ]),

    youtubeIframe(): string | null {
      if (this.localYoutubeLink && this.youtubeUrlRegex.test(this.localYoutubeLink)) {
        const videoId = this.getId(this.localYoutubeLink);
        return `<iframe width="560" height="315" src="//www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
      }

      return null;
    },
  },
  watch: {
    currentAddress() {
      this.loadCurrentAddressContent();
    },
  },
  mounted(): void {
    this.loadCurrentAddressContent();
  },
  methods: {
    ...mapActions('brand', [
      'setYoutubeLink',
      'setPicture',
      'setPictureTwo',
      'setPictureThree',
      'saveMedias',
      'setIsUpdated',
    ]),
    ...mapActions('recruiter', [
      'setCurrentAddress',
    ]),
    getId(url: string): string | null {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
    },
    mustBeYoutubeLink(value: string): boolean {
      return !helpers.req(value) || this.youtubeUrlRegex.test(value);
    },
    loadCurrentAddressContent(): void {
      if (this.currentAddress.youtubeLink) {
        this.setYoutubeLink(this.currentAddress.youtubeLink);
        this.localYoutubeLink = this.currentAddress.youtubeLink;
      } else {
        this.setYoutubeLink(null);
        this.localYoutubeLink = null;
      }

      if (this.currentAddress.picture) {
        this.setPicture(this.currentAddress.picture);
      } else {
        this.setPicture(null);
      }

      if (this.currentAddress.pictureTwo) {
        this.setPictureTwo(this.currentAddress.pictureTwo);
      } else {
        this.setPictureTwo(null);
      }

      if (this.currentAddress.pictureThree) {
        this.setPictureThree(this.currentAddress.pictureThree);
      } else {
        this.setPictureThree(null);
      }
    },
    setLocalYoutubeLink(youtubeLink: string): void {
      this.setIsUpdated(true);
      this.setYoutubeLink(youtubeLink);
    },
    checkDrop(event: DragEvent): void {
      event.preventDefault();
    },
    internalDropPicture(event: DragEvent, elementId: string, index: number): void {
      const element = document.querySelector(`#${elementId}`) as HTMLInputElement;
      element.files = event.dataTransfer?.files as FileList;
      this.onPictureChange(event, index);
    },
    dropPicture(event: DragEvent): void {
      this.internalDropPicture(event, 'picture', 0);
    },

    dropPictureTwo(event: DragEvent): void {
      this.internalDropPicture(event, 'pictureTwo', 1);
    },

    dropPictureThree(event: DragEvent): void {
      this.internalDropPicture(event, 'pictureThree', 2);
    },
    onPictureChange(e: HTMLInputEvent | DragEvent, index: number): void {
      const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
      if (!files?.length) return;

      const file = files[0];
      const maxFileSize = 2 * 1024 * 1024; // 2 MB

      if (!file.type.startsWith('image/')) {
        this.toast.error((this.$t('components.brand_media.errors.format') as string));
      } else if (file.size > maxFileSize) {
        this.toast.error((this.$t('components.brand_media.errors.size') as string));
      } else {
        this.setIsUpdated(true);
        this.createPicture(file, index);
      }
    },

    deleteVideo(): void {
      this.localYoutubeLink = '';
      this.setLocalYoutubeLink('');
    },

    deletePicture(): void {
      this.setPicture(null);
      this.setIsUpdated(true);
    },

    deletePictureTwo(): void {
      this.setPictureTwo(null);
      this.setIsUpdated(true);
    },
    deletePictureThree(): void {
      this.setPictureThree(null);
      this.setIsUpdated(true);
    },

    createPicture(file: Blob, index: number): void {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.localPictures[index] = (reader.result as string);

        switch (index) {
          case 0: {
            this.setPicture(this.localPictures[index]);

            break;
          }
          case 1: {
            this.setPictureTwo(this.localPictures[index]);

            break;
          }
          case 2: {
            this.setPictureThree(this.localPictures[index]);

            break;
          }
          default:
          // No default
        }
      });
      reader.readAsDataURL(file);
    },
    async save(): Promise<void> {
      try {
        await this.saveMedias();
        this.setCurrentAddress(this.currentAddress);
        this.confirmSaveModalOpened = false;
        this.toast.success((this.$t('components.brand_media.save.success') as string));
      } catch (error) {
        console.error(error);
        this.toast.error((this.$t('components.brand_media.save.error') as string));
      }
    },
  },
  validations() {
    return {
      localYoutubeLink: {
        validYoutubeLink: this.mustBeYoutubeLink,
      },
    };
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-media-description {
  font-size: 14px;
}

.address-video-delete {
  background: none;
  border: 0;
  position: absolute;
  right: 5px;
  top: 5px;
}

.form-label {
  color: $primary;
  font-size: 13px;
  font-weight: 700;
}

.dropzone {
  align-items: center;
  border: 2px dashed $primary;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100px;
}

.dropzone-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.dropzone-delete {
  background: transparent;
  border: 0;
  border-radius: 100%;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 5px;
}

.dropzone-list {
  display: grid;
  gap: $spacer;
  grid-template-columns: repeat(3, 1fr);
}

.dropzone-input {
  left: -9999px;
  position: absolute;
}

.video-input {
  background-color: #352bab0d;
  border: 2px dashed $primary;
  padding-right: 25%;
}

.video-link-icon {
  background-color: #352bab;
  color: #fff;
  display: none;
  padding: 5px 20px;
  right: 10px;
  top: 38px;

  @include mq($from: tablet) {
    display: block;
  }
}

.btn-small {
  height: 40pt;
}
</style>
