<template>
  <form @submit.prevent="submitSiret()">
    <div class="form-group" :class="{ 'is-invalid': siretError }">
      <label class="form-label" :for="'address_siret'">
        {{ $t('components.address_complete_siret_form.label.siret') }}
      </label>
      <input
        :id="'address_siret'"
        v-model="siret"
        class="form-control"
        type="text"
        name="address_siret"
        maxlength="14"
        :class="{ 'is-invalid': siretError }"
        placeholder="Insérez votre numéro Siret"
        @keyup="sanitizeSiret">

      <div v-if="siretError" class="invalid-feedback">
        {{ siretError }}
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-primary btn-rounded" type="submit" :disabled="disabled">Suivant</button>
    </div>
  </form>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import ActivitySector from '../interfaces/activity-sector';
import { CompleteStep } from '../store/modules/complete';

interface SiretResponse {
  activitySector: ActivitySector | null,
  address: string,
}

export default defineComponent({
  name: 'AddressCompleteSiretForm',
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      siret: '',
      siretError: undefined as (string | undefined),
    };
  },
  computed: {
    ...mapState('complete', [
      'address',
    ]),
    disabled(): boolean {
      return this.siret.length !== 14;
    },
  },
  watch: {},
  mounted() {},
  validations() {
    return {};
  },
  methods: {
    ...mapActions('complete', [
      'setStep',
      'setAddress',
      'setAddressQuery',
    ]),
    sanitizeSiret(): void {
      if (this.siret) {
        this.siret = this.siret.replace(/\D/, '');

        this.siretError = this.siret.length > 0 && this.siret.length !== 14 ? 'Votre SIRET doit contenir 14 caractères' : '';
      }
    },
    async submitSiret(): Promise<void> {
      this.siretError = undefined;

      const payload = {
        siret: this.siret,
      };

      try {
        const data = await window.ky.post('/api/siret/', { json: payload }).json<SiretResponse>();

        // Update address with SIRENE API return
        const localAddress = cloneDeep(this.address);

        if (data.activitySector) {
          localAddress.activitySector = data.activitySector;
        }

        this.setAddress(localAddress);
        this.setAddressQuery(data.address);
      } catch (error) {
        console.error(error);
      }

      this.setStep(CompleteStep.FORM);
    },
  },
});
</script>
