<template>
  <div v-if="localAddress" class="job-card card">
    <div class="card-body">
      <div class="d-flex flex-column h-100 w-100">
        <div class="job-card-name">{{ $t('components.free_application_card.title') }}</div>

        <div class="form-check form-switch custom-switch">
          <input
            id="free-application-active"
            v-model="localAddress.freeApplication"
            class="form-check-input btn-checked-red-to-green"
            type="checkbox"
            role="switch"
            :aria-checked="localAddress.freeApplication"
            @change="updateActive">
          <label class="form-check-label" for="free-application-active">
            {{ $t('components.job_card.active') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import Address from '../interfaces/address';

export default defineComponent({
  name: 'FreeApplicationCard',
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localAddress: null as (Address|null),
    };
  },
  computed: {
    ...mapState('recruiter', [
      'currentAddress',
    ]),
  },
  mounted(): void {
    this.localAddress = cloneDeep(this.currentAddress);
  },
  methods: {
    ...mapActions('recruiter', [
      'setCurrentAddress',
    ]),

    /**
   * Do the actual save with the API
   */
    async updateActive(): Promise<void> {
      if (!this.localAddress) {
        return;
      }

      try {
      // Patch the address's jobs
        const url = `/api/addresses/${this.currentAddress.uuid}/`;
        await window.ky.patch(url, { json: { freeApplication: this.localAddress?.freeApplication } }).json<Address>();
        this.setCurrentAddress(this.localAddress);
      } catch {
        this.toast.error((this.$t('components.free_application_card.update.error') as string));
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@include job-card();
</style>
