<template>
  <form class="form mx-1" enctype="multipart/form-data" @submit.prevent="submit()">
    <div class="form-group">
      <label class="form-label" :for="`address_name${localAddress.uuid}`">{{ $t('components.register_address.name.label') }}</label>
      <input
        :id="`address_name${localAddress.uuid}`"
        v-model="localAddress.name"
        class="form-control form-upper"
        type="text"
        maxlength="35"
        :placeholder="$t('components.register_address.name.placeholder')"
        name="address_name">
      <small class="form-text text-muted">{{ $t('form.helper.address_name') }}</small>
    </div>

    <div class="form-group">
      <label :for="`uploadFile${localAddress.uuid}`" class="form-label d-block">
        <div>{{ $t('components.manage_address.label.logo') }}</div>
        <v-file-indicator :file="selectedFile">
          <div class="mt-2">
            <small class="form-text steel-text">
              {{ $t('form.image.square') }} <br>
              {{ $t('form.image.format') }} <br>
              {{ $t('form.image.max_size') }}
            </small>
          </div>
        </v-file-indicator>
      </label>
      <input
        :id="`uploadFile${localAddress.uuid}`"
        type="file"
        class="form-control visually-hidden"
        @change="onFileChange($event as HTMLInputEvent)">
    </div>

    <div class="form-group">
      <label class="form-label" :for="`address_content${localAddress.uuid}`">{{ $t('components.register_address.content.label') }}</label>
      <vue-bootstrap-autocomplete
        :id="`address_content${localAddress.uuid}`"
        v-model="addressQuery"
        :max-matches="5"
        :show-all-results="true"
        :data="mapboxAddresses"
        :serializer="(item: MapboxAddress) => item.place_name"
        placeholder="Ajouter l'adresse de l’implantation"
        @hit="onSelectAddress($event)"
        @input="lookupAddressHandler"
      />
      <small class="form-text text-muted">{{ $t('form.helper.address') }}</small>
    </div>

    <v-activity-sector-field
      :address="localAddress"
      :index="0"
      @on-select-activity-sector="onSelectActivitySector"
    />

    <div class="form-check form-switch custom-switch mb-3 p-0">
      <label class="form-check-label " :for="`active${localAddress.id}`">
        {{ $t('components.register_address.receive_application_by_email') }}
      </label>
      <input
        :id="`receive_application_by_email${localAddress.id}`"
        v-model="localAddress.receiveApplicationByEmail"
        class="form-check-input btn-checked-red-to-green mx-0"
        type="checkbox"
        role="switch"
        :aria-checked="localAddress.receiveApplicationByEmail">
    </div>

    <div v-if="localAddress.receiveApplicationByEmail" class="form-group">
      <label class="form-label" :for="`address_email${localAddress.uuid}`">
        {{ $t('components.register_address.email') }}
      </label>
      <input
        :id="`address_email${localAddress.uuid}`"
        v-model="localAddress.email"
        class="form-control"
        type="email"
        name="address_email"
      >
    </div>

    <div v-if="localAddress.receiveApplicationByEmail" class="form-group">
      <div class="form-check">
        <input
          :id="`address_frequency_daily${localAddress.uuid}`"
          v-model="localAddress.frequency"
          :name="`address_frequency${localAddress.uuid}`"
          class="form-check-input btn-radio-inversed"
          type="radio"
          value="daily">
        <label class="form-check-label font-weight-normal label-text" :for="`address_frequency_daily${localAddress.uuid}`">
          {{ $t('components.register_address.frequency.daily') }}
        </label>
      </div>

      <div class="form-check">
        <input
          :id="`address_frequency_weekly${localAddress.uuid}`"
          v-model="localAddress.frequency"
          :name="`address_frequency${localAddress.uuid}`"
          class="form-check-input btn-radio-inversed"
          type="radio"
          value="weekly">
        <label class="form-check-label font-weight-normal label-text" :for="`address_frequency_weekly${localAddress.uuid}`">
          {{ $t('components.register_address.frequency.weekly') }}
        </label>
      </div>
    </div>

    <div class="d-flex justify-content-center gap-3 mt-5">
      <button type="button" class="btn btn-outline-primary btn-rounded" @click.prevent="$emit('on-cancel')">
        {{ $t('actions.cancel') }}
      </button>
      <button class="btn btn-primary btn-rounded btn-save" :disabled="addressError" type="submit">
        {{ $t('actions.validate') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { cloneDeep, DebouncedFunc, throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';

import ActivitySector from '../interfaces/activity-sector';
import Address from '../interfaces/address';
import { HTMLInputEvent } from '../interfaces/events';
import MapboxAddress from '../interfaces/mapbox-address';
import { searchMapboxAddress } from '../services/mapbox';

export default defineComponent({
  name: 'AddressForm',
  props: {
    modelValue: {
      type: Object as PropType<Address>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'on-cancel', 'on-submit'],

  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localAddress: cloneDeep(this.modelValue),
      addressQuery: '',
      mapboxAddress: {} as MapboxAddress,
      mapboxAddresses: [] as MapboxAddress[],
      lookupAddressHandler: throttle(this.searchAddress, 1000) as DebouncedFunc<() => void>,
      selectedFile: '',
    };
  },
  computed: {
    addressError(): boolean {
      return this.localAddress.name === ''
     || !this.addressHasContent(this.localAddress)
     || !this.addressQuery
     || (!this.localAddress.email && this.localAddress.receiveApplicationByEmail);
    },
  },
  watch: {},
  mounted(): void {
    if (Object.keys(this.localAddress.content).length > 0 && this.localAddress.content.constructor === Object) {
      this.addressQuery = this.localAddress.content.placeName;
    }
  },
  methods: {
    addressHasContent(address: Address): boolean {
      return (Object.keys(address.content).length > 0 && address.content.constructor === Object);
    },
    async searchAddress(): Promise<void> {
      if (this.addressQuery && this.addressQuery.length > 2) {
        this.mapboxAddress = {} as MapboxAddress;
        this.localAddress.content = this.mapboxAddress;

        const data = await searchMapboxAddress(this.addressQuery);
        this.mapboxAddresses = data.suggestions;
      } else {
        this.mapboxAddresses = [];
      }
    },
    onSelectAddress(selectedAddress: MapboxAddress): void {
      this.addressQuery = selectedAddress.place_name;
      this.mapboxAddress = selectedAddress;
      this.mapboxAddresses = [];
      this.localAddress.content = this.mapboxAddress;
    },
    onFileChange(e: HTMLInputEvent | DragEvent): void {
      const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
      if (!files?.length) return;
      this.selectedFile = files[0].name;
      this.createImage(files[0]);
    },
    createImage(file: Blob): void {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.localAddress.logo = (reader.result as string);
      });
      reader.readAsDataURL(file);
    },

    submit(): void {
      this.$emit('update:modelValue', this.localAddress);
      this.$emit('on-submit');
    },
    onSelectActivitySector(activitySector: ActivitySector) {
      this.localAddress.activitySector = activitySector;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.form-upper {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}

</style>
