import Cookies from 'js-cookie';
// import VueRouter, { Route, RouteConfig } from 'vue-router';
import { createRouter as _createRouter, createWebHistory, Route } from 'vue-router';

import routesNames from '@/js/router/route-names';
import State from '@/js/store/state';

import createStore from '../store/index';

const store = createStore();

export default function createRouter() {
  const router = _createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/app',
        name: routesNames.home,
        meta: { anonymousOnly: true },
        redirect: '/app/login',
      },
      {
        path: '/app/login',
        name: routesNames.login,
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-login' },
      },
      {
        path: '/app/lost-password',
        name: routesNames.lostPassword,
        component: () => import(/* webpackChunkName: "lost-password" */ '../views/LostPassword.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-lost-password' },
      },
      {
        path: '/app/lost-password/confirm',
        name: routesNames.lostPasswordConfirm,
        component: () => import(/* webpackChunkName: "lost-password-confirm" */ '../views/LostPasswordConfirm.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-lost-password' },
      },
      {
        path: '/app/register',
        name: routesNames.register,
        component: () => import(/* webpackChunkName: "register" */ '../views/Register/Register.vue'),
        meta: { anonymousOnly: true },
      },
      {
        path: '/app/register/1',
        name: routesNames.registerStep1,
        component: () => import(/* webpackChunkName: "registerStep1" */ '../views/Register/RegisterStep1.vue'),
        meta: { anonymousOnly: true, bodyClass: 'view-register-step-1' },
      },
      {
        path: '/app/register/2',
        name: routesNames.registerStep2,
        component: () => import(/* webpackChunkName: "registerStep2" */ '../views/Register/RegisterStep2.vue'),
        meta: { anonymousOnly: false, bodyClass: 'view-register-step-2' },
      },
      {
        path: '/app/register/success',
        name: routesNames.registerSuccess,
        component: () => import(/* webpackChunkName: "registerSuccess" */ '../views/Register/RegisterSuccess.vue'),
        meta: { requiresAuth: true, bodyClass: 'view-register-success' },
      },
      {
        path: '/app/dashboard',
        name: routesNames.dashboard,
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/app/jobs',
        name: routesNames.jobs,
        component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/app/brand',
        name: routesNames.brand,
        component: () => import(/* webpackChunkName: "brand" */ '../views/Brand.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/app/brand/preview',
        name: routesNames.brandPreview,
        component: () => import(/* webpackChunkName: "brandPreview" */ '../views/BrandPreview.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/app/resumes',
        component: () => import(/* webpackChunkName: "resumes" */ '../views/Resume.vue'),
        meta: { requiresAuth: true, needLayout: true },
        children: [
          {
            path: '',
            name: routesNames.resumes,
            component: () => import(/* webpackChunkName: "resumes" */ '../views/ResumeList.vue'),
            meta: { requiresAuth: true, needLayout: true },
          },
          {
            path: ':uuid',
            name: routesNames.candidateProfile,
            component: () => import(/* webpackChunkName: "candidate-profile" */ '../views/ResumeDetail.vue'),
            meta: { requiresAuth: true, needLayout: true },
          },
        ],
      },
      {
        path: '/app/public-resume/:uuid',
        component: () => import(/* webpackChunkName: "public-resume" */ '../views/PublicResume.vue'),
        meta: { requiresAuth: false, needLayout: false },
      },
      {
        path: '/app/account',
        name: routesNames.account,
        component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/app/addresses',
        name: routesNames.addresses,
        component: () => import(/* webpackChunkName: "addresses" */ '../views/Addresses.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/app/addresses/add',
        name: routesNames.addNewAddress,
        component: () => import(/* webpackChunkName: "add-new-address" */ '../views/AddNewAddress.vue'),
        meta: { requiresAuth: true, needLayout: false },
      },
      {
        path: '/app/recruiter/reset',
        name: routesNames.reset,
        component: () => import(/* webpackChunkName: "reset" */ '../views/Reset.vue'),
        meta: { requiresAuth: true, needLayout: false },
      },
      {
        path: '/app/addresses/:uuid/complete',
        name: routesNames.addressComplete,
        component: () => import(/* webpackChunkName: "address-complete" */ '../views/AddressComplete.vue'),
        meta: { requiresAuth: false, needLayout: false },
      },
      {
        path: '/app/my-qr-codes',
        name: routesNames.myQrCodes,
        component: () => import(/* webpackChunkName: "my-qr-codes" */ '../views/MyQrCodes.vue'),
        meta: { requiresAuth: true, needLayout: true },
      },
      {
        path: '/:catchAll(.*)',
        name: routesNames.pageNotFound,
        component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'),
        meta: { needLayout: false },
      },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  function checkUserAndRedirect(to: Route, next: Function) {
    const { user } = (store.state as State).recruiter;
    if (
      user
      && !user.recruiter.hasPayedOrders
      && to.name
      && !['AddNewAddress', 'RegisterStep2', 'RegisterSuccess', 'Reset'].includes(to.name)
      && !to.query.checkout_session_id
    ) {
      next({ name: routesNames.reset, query: { redirect: to.fullPath } });
    } else {
      next();
    }
  }

  const requiresAuthGuard = async (to: Route, from: Route, next: Function) => {
    const accessToken = Cookies.get('jdmcv_access');
    const isLoggedIn = !!accessToken;

    if (isLoggedIn) {
      const { user } = (store.state as State).recruiter;

      if (!user) {
        try {
          await store.dispatch('recruiter/getProfile');
        } catch (error) {
          console.error(error);
        }
      }

      checkUserAndRedirect(to, next);
    } else {
      next({
        name: routesNames.login,
        query: { redirect: to.fullPath },
      });
    }
  };

  const anonymousOnlyGuard = (to: Route, from: Route, next: Function) => {
    const isAnonymous = !(store.state as State).recruiter.user;

    if (isAnonymous) {
      next();
    } else {
      next({
        name: routesNames.dashboard,
      });
    }
  };

  // This router guard redirect the user to the login or dashboard,
  // depending on its connected state
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      await requiresAuthGuard(to, from, next);
    } else if (to.matched.some((record) => record.meta.anonymousOnly)) {
      anonymousOnlyGuard(to, from, next);
    } else {
      next();
    }
  });

  // This router guard add body classes on route navigation
  router.afterEach((to, from) => {
    const oldBodyClass: string = from.meta?.bodyClass;
    const newBodyClass: string = to.meta?.bodyClass;

    if (oldBodyClass && document.body.classList.contains(oldBodyClass)) {
      document.body.classList.remove(oldBodyClass);
    }

    if (newBodyClass && !document.body.classList.contains(newBodyClass)) {
      document.body.classList.add(newBodyClass);
    }
  });

  return router;
}
