// https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components

export default function addComponentGlobally(app) {
// eslint-disable-next-line unicorn/prefer-module
  const requireComponent = require.context(
    '.',
    false,
    /[\w-]+\.vue$/,
  );

  for (const fileName of requireComponent.keys()) {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
      .split('-')
      .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      .join('');

    app.component(`V${componentName}`, componentConfig.default || componentConfig);
  }
}
