<template>
  <div class="row mb-5 mt-4 mt-md-0">
    <div class="form col-md-8">
      <div v-if="address" class="add-address-form">
        <div class="form-group">
          <label class="form-label" for="address_name">{{ $t('components.register_address.name.label') }}</label>
          <input
            id="address_name"
            v-model="address.name"
            class="form-control"
            type="text"
            maxlength="18"
            :placeholder="$t('components.register_address.name.placeholder')"
            name="address_name">
          <small class="form-text text-muted">{{ $t('components.register_address.qrcode_helper') }}</small>
        </div>

        <div class="form-group">
          <label for="logo">{{ $t('components.register_address.logo') }}</label>
          <input id="logo" type="file" class="form-control" name="logo" @change="onFileChange($event as HTMLInputEvent)">
          <small class="form-text text-xs text-muted">{{ $t('form.image.format') }}<br>{{ $t('form.image.max_size') }}</small>
        </div>

        <div class="form-group">
          <label class="form-label" for="address_content">{{ $t('components.register_address.content') }}</label>
          <vue-bootstrap-autocomplete
            id="address_content"
            v-model="addressQuery"
            class="mb-1"
            :max-matches="5"
            :show-all-results="true"
            :data="mapboxAddresses"
            :serializer="(item: MapboxAddress) => item.place_name"
            placeholder=""
            @hit="onSelectAddress($event)"
            @input="lookupAddressHandler"
          />
          <small class="form-text text-muted">{{ $t('form.helper.address') }}</small>
          <small class="text-black-50">{{ $t('components.account_form.address_helper') }}</small>
        </div>

        <v-activity-sector-field
          :address="address"
          :index="0"
          @on-select-activity-sector="onSelectActivitySector"
        />

        <div class="form-group">
          <label class="form-label" :for="'address_qrcode_town'">{{ $t('components.register_address.qrcode_town') }}</label>
          <input
            :id="'address_qrcode_town'"
            v-model="address.qrcodeTown"
            class="form-control"
            type="text"
            name="address_qrcode_town"
            maxlength="18">
        </div>

        <!-- No more qr code in the register process, but it will change, I'm sure
        <h3 class="text-l mt-4">{{ $t('components.register_address.additional_order') }}</h3>
        <div class="register-address-products mb-3 row">
          <v-register-address-product
            v-for="product in address.products"
            :key="product.id"
            :minimum-quantity="['Caisse', 'Vitrine'].includes(product.name) ? 1 : 0"
            :product="product"
            :quantity="product.quantity"
            @update:quantity="product.quantity = $event; updateSelectedProducts()" />
        </div>
        <div class="text-s mb-2">*&nbsp;{{ $t('views.register_step_2.shipping_info') }}</div>
        -->
        <button type="button" class="btn btn-primary d-flex align-items-center" :disabled="addressError" @click.prevent="saveAddress">
          <div v-if="loading" class="spinner-border text-white" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          {{ $t('actions.save') }}
        </button>
      </div>
    </div>
    <div class="col-md-4">
      <div class="add-new-address-summary mt-5 mt-md-0">
        <h2 class="h3 text-xxl add-new-address-summary-title">{{ $t('components.register_summary.title') }}</h2>
        <div class="add-new-address-summary-subscription">
          <div class="fw-bold mb-2">Abonnement {{ intervalInMonth }} {{ $t('components.subscription_card.month') }}</div>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-2">
          <small class="fw-bold">{{ $t('components.register_summary.quantity') }} 1</small>
          <div class="add-new-address-summary-detail-total-price">
            <strong>{{ priceSelected.unitAmount / 100 }}€ {{ $t('price.without_taxes') }}</strong>
          </div>
        </div>
        <div v-if="selectedProducts.length > 0" class="add-new-address-summary-detail mt-2">
          <div class="fw-bold mb-3">{{ $t('components.register_summary.qrcode') }}</div>
          <div>
            <div class="d-flex align-items-center">
              <span>{{ address.name }}</span>
              <div class="add-new-address-summary-detail-total-price">
                <strong>{{ addressTotal / 100 }}€ {{ $t('price.without_taxes') }}</strong>
              </div>
            </div>

            <div class=" mt-3">
              <div v-for="product in address.products" :key="product.id">
                <div
                  v-if="product.quantity && product.quantity > 0"
                  class="d-flex align-items-center justify-content-between add-new-address-summary-product"
                >
                  <div>{{ product.name }} - {{ $t('components.register_summary.quantity') }} {{ product.quantity }}</div>
                  <div class="add-new-address-summary-detail-price">
                    {{ product.prices[0].unitAmount * product.quantity / 100 }}€&nbsp;{{ $t('price.without_taxes') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-new-address-summary-detail">
          <div class="d-flex align-items-center justify-content-between">
            <div><strong>{{ $t('components.register_summary.total') }}</strong></div>
            <div class="add-new-address-summary-detail-total-price">
              <strong>{{ total }}€ {{ $t('price.without_taxes') }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DebouncedFunc, throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import {
  mapActions, mapGetters, mapState,
} from 'vuex';

import ActivitySector from '../interfaces/activity-sector';
import AddressRegistration from '../interfaces/address-registration';
import { HTMLInputEvent } from '../interfaces/events';
import MapboxAddress, { MapboxAddressContext } from '../interfaces/mapbox-address';
import PaginatedResult from '../interfaces/pagination';
import Price from '../interfaces/price';
import Product from '../interfaces/product';
import StripeLineItem from '../interfaces/stripe.line-item';
import { searchMapboxAddress } from '../services/mapbox';

export default defineComponent({
  name: 'NewAddressForm',
  props: {
    modalOpened: {
      type: Boolean,
      required: true,
    },
    subscriptionSelected: {
      type: Object as PropType<Product>,
      required: true,
    },
    priceSelected: {
      type: Object as PropType<Price>,
      required: true,
    },
  },
  emits: ['getActivitySectors'],

  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading: false,
      address: {
        name: '',
        content: {} as MapboxAddress,
        activitySector: 0,
        products: [] as Array<Product>,
        subscription: null,
        logo: null,
        qrcodeTown: '',
      } as AddressRegistration,
      addressQuery: '',
      mapboxAddresses: [] as MapboxAddress[],
      selectedProducts: [] as Product[],
      lineItems: [] as StripeLineItem[],
      lookupAddressHandler: throttle(this.searchAddress, 1000) as DebouncedFunc<() => void>,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'user',
      'currentAddress',
    ]),
    ...mapGetters('register', [
      'goods',
      'subscriptions',
    ]),

    addressError(): boolean {
      return this.address.name === ''
        || this.address.activitySector === 0
        || !this.addressHasContent(this.address)
        || !this.address.qrcodeTown
        || !this.addressQuery;
    },

    intervalInMonth(): number {
      let intervalInMonth = 0;

      if (this.priceSelected.recurring) {
        if (this.priceSelected.recurring.interval === 'month') {
          intervalInMonth = this.priceSelected.recurring.intervalCount;
        } else if (this.priceSelected.recurring.interval === 'year') {
          intervalInMonth = this.priceSelected.recurring.intervalCount * 12;
        }
      }
      return intervalInMonth;
    },

    total(): number {
      return (this.priceSelected.unitAmount + this.addressTotal) / 100;
    },

    addressTotal(): number {
      let addressTotal = 0;

      if (this.address.products) {
        // eslint-disable-next-line max-len
        addressTotal += this.selectedProducts.reduce((accumulator: number, product: Product) => accumulator + product.prices[0].unitAmount * (product.quantity ?? 0), 0);
      }

      return addressTotal;
    },
  },
  watch: {},
  async mounted(): Promise<void> {
    window.ky.get('/api/products/').json().then((data: PaginatedResult<Product>) => {
      this.setProducts(data.results);
      this.address.products = this.goods;
    });

    if (this.$route.query.checkout_session_id) {
      await this.getProfile();
    }
  },
  validations() {
    return {};
  },
  methods: {
    ...mapActions('register', [
      'setProducts',
    ]),
    ...mapActions('recruiter', [
      'getProfile',
    ]),
    addressHasContent(address: AddressRegistration): boolean {
      return (Object.keys(address.content).length > 0 && address.content.constructor === Object);
    },
    // eslint-disable-next-line consistent-return
    updateSelectedProducts() {
      if (!this.address.products) return false;
      this.selectedProducts = this.address.products.filter(
        (product) => product.quantity !== undefined && product.quantity !== null && product.quantity > 0,
      );
    },
    onFileChange(e: HTMLInputEvent | DragEvent): void {
      const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
      if (!files?.length) return;

      this.createImage(files[0]);
    },

    createImage(file: Blob): void {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.address.logo = (reader.result as string);
      });
      reader.readAsDataURL(file);
    },

    async searchAddress(): Promise<void> {
      if (this.addressQuery && this.addressQuery.length > 2) {
        this.address.content = {} as MapboxAddress;
        const data = await searchMapboxAddress(this.addressQuery);
        this.mapboxAddresses = data.suggestions;
      } else {
        this.mapboxAddresses = [];
      }
    },

    onSelectAddress(selectedAddress: MapboxAddress): void {
      this.addressQuery = selectedAddress.place_name;
      this.address.content = selectedAddress;
      if (this.address.content.context) {
        this.address.content.context.map((item: MapboxAddressContext) => {
          if (item.id.split('.')[0] === 'place') {
            this.address.qrcodeTown = item.text.length > 18 ? `${item.text.slice(0, 18)}` : item.text;
          }
          return true;
        });
      }
    },

    onSelectActivitySector(activitySector: ActivitySector): void {
      this.address.activitySector = activitySector.id;
    },

    saveAddress(): void {
      this.redirectToStripe();
    },

    // eslint-disable-next-line consistent-return
    async redirectToStripe() {
      if (this.addressError) return false;

      this.loading = true;

      this.lineItems.push({
        price: this.priceSelected.id,
        quantity: 1,
      });

      for (const product of this.selectedProducts) {
        if (product.quantity) {
          this.lineItems.push({
            price: product.prices[0].id,
            quantity: product.quantity,
          });
        }
      }

      const payload = {
        lineItems: this.lineItems,
        addresses: [this.address],
        price: this.priceSelected,
        subscription: this.subscriptionSelected,
      };

      try {
        const data = await window.ky.post('/ecommerce/address-payment-link/', { json: payload }).json();

        if (data.url) {
          window.location.href = data.url;
        }
      } catch (error) {
        console.error(error);
        this.toast.error((this.$t('views.addresses.new_address_form.error') as string));
      } finally {
        this.loading = false;
      }
    },

    reset(): void {
      // Reset modal selection
      this.address.activitySector = 0;
      this.$emit('getActivitySectors', '');
    },
  },
});

</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.form {
  padding-right: 50px;
}

.add-address-form {
  .register-address-product {
    display: block;
  }
}

.add-new-address {
  position: sticky;
  top: 0;
}

.add-new-address-summary-subscription {
  border-bottom: 1px solid $gray-300;
  padding: $spacer 0 $spacer;

  @include mq($from: tablet) {
    padding: $spacer * 3 0 $spacer;
  }
}

.register-address-products {
  display: grid;
  grid-gap: math.div($grid-gutter-width, 2);
  grid-template-columns: 1fr;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.add-new-address-summary-detail {
  border-bottom: 1px solid $gray-300;
  padding: $spacer 0;

  .address-name {
    text-decoration: none;
  }

  .icon {
    margin-left: auto;
    margin-right: 10px;
  }
}

.add-new-address-summary-detail-total-price {
  color: #000;
  font-size: 16px;
  margin-left: auto;
  text-align: right;
}

.add-new-address-summary-detail-price {
  color: $gray-600;
  margin-left: 10px;
}

.add-new-address-summary-actions {
  display: grid;
  margin-top: 40px;
}

.add-new-address-summary-product {
  color: $gray-600;
}

.subscription-bonus {
  color: $gray-600;
  font-style: italic;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: left;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }
}
</style>
