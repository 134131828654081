import Cookies from 'js-cookie';
import ky, { KyInstance } from 'ky';
import VueRouter from 'vue-router';
import { Store } from 'vuex';

import { ApiRefreshResponse } from '@/js/interfaces/api';
import routesNames from '@/js/router/route-names';
import State from '@/js/store/state';

const kyService = ((store: Store<State>, router: VueRouter) => {
  let instance: KyInstance;

  const createInstance = () => {
    instance = ky.create({
      timeout: 10_000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      hooks: {
        beforeRequest: [
          (request) => {
            const accessToken = Cookies.get('jdmcv_access');

            if (accessToken) {
              request.headers.set('Authorization', `Bearer ${accessToken}`);
            }
          },
        ],
        afterResponse: [
          async (request, options, response) => {
            const originalConfig = { url: request.url, retry: false };

            // When the token fails to refresh, logout and redirect to the login page
            if (originalConfig.url.endsWith('/api/token/refresh/') && response.status === 401) {
              await store.dispatch('recruiter/logout');
              await router.push({ name: routesNames.login });

              return response;
            }

            // When the access token was expired, try to refresh it
            if (!originalConfig.url.endsWith('/api/token/') && response.status === 403 && !originalConfig.retry) {
              originalConfig.retry = true;

              const refresh = Cookies.get('jdmcv_refresh');
              const refreshResponse = await instance.post('/api/token/refresh/', { json: { refresh } }).json<ApiRefreshResponse>();
              const { access, refresh: newRefresh } = refreshResponse;
              await store.dispatch('recruiter/setAccess', access);
              await store.dispatch('recruiter/setRefresh', newRefresh);

              // Retry the original request with the new access token
              const newOptions = {
                ...options,
                headers: {
                  ...options.headers,
                  Authorization: `Bearer ${access}`,
                },
              };

              return ky(request, newOptions);
            }

            return response;
          },
        ],
      },
    });

    return instance;
  };

  return {
    getInstance: () => {
      if (!instance) {
        createInstance();
      }

      return instance;
    },
  };
});

export default kyService;
