import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete';
import VuePaginatePlugin from '@svifty7/vue-paginate';
import { createHead, VueHeadMixin } from '@unhead/vue';
// Load vendor components
import { createApp } from 'vue';
import ToastPlugin from 'vue-toast-notification';
import VueBootstrapAutocomplete from 'vue3-bootstrap-autocomplete';

import App from './App.vue';
import addComponentGlobally from './components';
import i18n from './i18n';
import createRouter from './router';
import kyService from './services/ky';
import createStore from './store';

const router = createRouter();
const store = createStore();

const app = createApp(App);
// Autocomplete
app.use(i18n);
app.use(router);
app.use(store);
app.use(DisableAutocomplete);

addComponentGlobally(app);
// ky
// Create a single ky instance
const ky = kyService(store, router).getInstance();
window.ky = ky;

// Vue Meta
const head = createHead();
app.mixin(VueHeadMixin);
app.use(head);

// Toast
app.use(ToastPlugin);

// Typeahead
app.component('VueBootstrapAutocomplete', VueBootstrapAutocomplete);

// VuePaginate
app.use(VuePaginatePlugin);

// Load app
const appElement = document.querySelector('#app');
if (appElement) {
  const { dataset } = appElement;

  // Get sprite path from data set
  localStorage.setItem('spritePath', dataset.spritePath ?? '');

  // eslint-disable-next-line no-new
  app.mount('#app');
}
