import Cookies from 'js-cookie';
import { createI18n } from 'vue-i18n';

import messages from './messages';

const locale = Cookies.get('lang') || 'fr';

const i18n = createI18n({
  locale,
  fallbackLocale: 'fr',
  legacy: false,
  messages,
});

export default i18n;
