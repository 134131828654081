<template>
  <div>
    <div class="logo">
      <router-link v-if="loggedIn" :to="{ name: routeNames.dashboard }">
        <img v-if="color" src="@/images/decide-logo-blue.svg" alt="decide.work">
        <img v-else src="@/images/decide-logo.svg" alt="decide.work">
      </router-link>
      <a v-else href="/">
        <img v-if="color" src="@/images/decide-logo-blue.svg" alt="decide.work">
        <img v-else src="@/images/decide-logo.svg" alt="decide.work">
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import routesNames from '../router/route-names';

export default defineComponent({
  name: 'LogoView',
  props: {
    color: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('recruiter', [
      'loggedIn',
    ]),
    routeNames() {
      return routesNames;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.logo {
  margin: $spacer 0;
  padding-left: 0;
}

</style>
