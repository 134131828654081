import Modal from 'bootstrap/js/dist/modal';

import i18n from './js/i18n';

// Get the spritePath from the data attribute on the body element, or use an empty string if not present
const spritePath = document.body.dataset.spritePath ?? '';

const minLength = 8;

const invalidLengthMessage = i18n.global.t('form.validation.min_length');

const placeholderMessage = i18n.global.t('components.register_information.password.placeholder').toString();

// Define SVG icons for showing/hiding password
const iconEye = `<svg class="icon-eye icon-eye-dims"><use href="${spritePath}#eye"></use></svg>`;
const iconEyeHide = `<svg class="icon-eye-hide icon-eye-hide-dims"><use href="${spritePath}#eye-hide"></use></svg>`;

// Wait for the DOM to be fully loaded
document.addEventListener('DOMContentLoaded', () => {
  // Get references to the password-related elements in the DOM
  const setPasswordModalElement = document.querySelector('#set-password-modal');
  const setPasswordUpdatedModalElement = document.querySelector('#set-password-updated-modal');

  if (setPasswordModalElement) {
    // Get references to password fields, form, and submit button
    const password1 = document.querySelector('#id_new_password1');
    const password2 = document.querySelector('#id_new_password2');
    const form = document.querySelector('#set-password-form');
    const passwordButton = document.querySelector('#set-password-btn');

    // Handle the click event for the password visibility toggle icon
    const handleIconClick = (event: Event) => {
      const target = event.currentTarget as HTMLElement;
      const show = target.dataset.show === 'true';
      const inputId = target.dataset.inputId as string;
      const input = document.querySelector(`#${inputId}`) as HTMLInputElement;

      if (show) {
        target.innerHTML = iconEye;
        target.dataset.show = 'false';
        input.type = 'password';
      } else {
        target.innerHTML = iconEyeHide;
        target.dataset.show = 'true';
        input.type = 'text';
      }
    };

    // Create a new Modal instance for the password setting modal and display it
    const setPasswordModal = new Modal(setPasswordModalElement);
    setPasswordModal.show();

    // Insert the hint about the max number of character
    password1.parentElement?.insertAdjacentHTML(
      'afterend',
      `<div class="form-text steel-text d-block" data-input-id="id_new_password1">${invalidLengthMessage}</div>`,
    );
    password2.parentElement?.insertAdjacentHTML(
      'afterend',
      `<div class="form-text steel-text d-block" data-input-id="id_new_password2">${invalidLengthMessage}</div>`,
    );

    // Change the placeholder of the password
    password1.setAttribute('placeholder', placeholderMessage);
    password2.setAttribute('placeholder', placeholderMessage);

    // Set the minimum length of the input
    password1.setAttribute('minLength', `${minLength}`);
    password2.setAttribute('minLength', `${minLength}`);

    // Insert the eye icons for password visibility next to the password fields
    password1.insertAdjacentHTML('afterend', `<div class="icon" data-show="false" data-input-id="id_new_password1">${iconEye}</div>`);
    password2.insertAdjacentHTML('afterend', `<div class="icon" data-show="false" data-input-id="id_new_password2">${iconEye}</div>`);

    // Add click event listeners to the eye icons
    for (const icon of document.querySelectorAll('.icon')) {
      icon.addEventListener('click', handleIconClick);
    }

    // Add a keyup event listener to the form control elements
    for (const element of document.querySelectorAll('.form-control')) {
      element.addEventListener('keyup', () => {
        if (form.checkValidity()) {
          passwordButton.classList.remove('btn-light-blue');
          passwordButton.classList.add('btn-primary');
          passwordButton.disabled = false;
        } else {
          passwordButton.classList.remove('btn-primary');
          passwordButton.classList.add('btn-light-blue');
          passwordButton.disabled = true;
        }
        if (element.value.length < minLength) {
          element.classList.add('is-invalid');
        } else {
          element.classList.remove('is-invalid');
        }
      });
    }
  }

  if (setPasswordUpdatedModalElement) {
    // Create a new Modal instance for the password updated modal and display it
    const setPasswordUpdatedModal = new Modal(setPasswordUpdatedModalElement);
    setPasswordUpdatedModal.show();
  }
});
