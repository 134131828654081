<template>
  <div :id="id" class="modal fade" tabindex="-1" :aria-labelledby="`${id}Label`" aria-hidden="true">
    <div :class="modalDialogClasses">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BsModal from 'bootstrap/js/dist/modal';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyModal',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      modal: undefined as (BsModal | undefined),
    };
  },
  computed: {
    modalDialogClasses(): string[] {
      const classes = ['modal-dialog', 'modal-dialog-centered'];

      if (this.size) {
        classes.push(this.size);
      }

      return classes;
    },
  },
  watch: {
    modelValue(newValue) {
      this.onModalOpenedChange(newValue);
    },
  },
  mounted(): void {
    const modalElement = document.querySelector(`#${this.id}`);

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', () => {
        this.$emit('update:modelValue', false);
      });
      this.modal = new BsModal(modalElement);
    }
  },
  methods: {
    onModalOpenedChange(opened: boolean): void {
      if (opened) {
        (this.modal as BsModal).show();
      } else {
        (this.modal as BsModal).hide();
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import '~sass-mq/mq';

.modal {
  color: $body-color;
}

.modal-body {
  padding-top: 0;
}

.new-job-modal {
  .modal-content {
    padding: 24px;

    @include mq($from: tablet) {
      padding: 32px;
    }
  }

  .modal-header,
  .modal-body {
    padding: 0;
  }
}
</style>
