import { createStore as _createStore } from 'vuex';

import brand from '@/js/store/modules/brand';
import complete from '@/js/store/modules/complete';
import recruiter from '@/js/store/modules/recruiter';
import register from '@/js/store/modules/register';

let store: Store<State>;

export default function createStore() {
  if (!store) {
    store = _createStore({
      strict: process.env.NODE_ENV !== 'production',
      modules: {
        recruiter,
        register,
        brand,
        complete,
      },
    });
  }

  return store;
}
