<template>
  <div class="job-card card">
    <div class="card-body">
      <div class="d-flex flex-column h-100 w-100">
        <div class="d-flex justify-content-between pb-2">
          <button type="button" class="btn btn-link btn-icon btn-update p-0" @click.prevent="editModalOpened = true;">
            <v-sprite icon="gear" />
            <span class="underline-text">{{ $t('actions.update') }}</span>
          </button>
          <a class="job-card-delete" href="" @keydown.enter.prevent="removeJob" @click.prevent="removeJob">
            <v-sprite icon="trash" />
            <span class="underline-text">{{ $t('actions.delete') }}</span>
          </a>
        </div>

        <div class="job-card-name">{{ localJob.name }}</div>

        <div class="selected-values">
          <div v-if="localJob.contract" class="value-badge">{{ localJob.contract.name }}</div>
          <div v-if="localJob.workingTime" class="value-badge">{{ localJob.workingTime.name }}</div>
          <div v-if="localJob.remote" class="value-badge">{{ localJob.remote.name }}</div>
        </div>

        <div class="custom-switchs">
          <div class="form-check form-switch custom-switch">
            <input
              :id="`active${localJob.id}`"
              v-model="localJob.active"
              class="form-check-input btn-checked-red-to-green"
              type="checkbox"
              role="switch"
              :aria-checked="localJob.active"
              @change="updateActive">
            <label class="form-check-label" :for="`active${localJob.id}`">
              {{ $t('components.job_card.active') }}
            </label>
          </div>

          <div class="form-check form-switch custom-switch">
            <input
              :id="`urgent${localJob.id}`"
              v-model="localJob.urgent"
              class="form-check-input btn-checked-red-to-green"
              type="checkbox"
              role="switch"
              :aria-checked="localJob.urgent"
              @change="updateUrgent">
            <label class="form-check-label" :for="`urgent${localJob.id}`">
              {{ $t('components.job_card.urgent') }}
            </label>
          </div>
        </div>

        <div class="job-card-cta d-flex justify-content-end">
          <div v-if="formattedSalary">
            {{ formattedSalary }}
          </div>
        </div>
      </div>
    </div>

    <v-modal :id="`standByModalId${localJob.id}`" v-model="standByModalOpened" @update:modal-opened="cancelStandBy">
      <h5 class="stand-by-title text-center">Désactiver un métier</h5>
      <p class="stand-by-content text-center">
        Attention en désactivant ce métier, votre implantation ne sera plus visible sur votre QR Code.
      </p>

      <div class="stand-by-cta">
        <button type="button" class="btn btn-rounded btn-outline-primary" @click.prevent="cancelStandBy">Annuler</button>
        <button type="button" class="btn btn-rounded btn-primary" @click.prevent="standBy()">Mettre en veille</button>
      </div>
    </v-modal>

    <v-modal :id="`editModalId${localJob.id}`" v-model="editModalOpened" size="modal-lg">
      <v-edit-job-form
        v-model="localJob"
        @cancel="editModalOpened = false"
        @job-updated="onJobUpdated($event)" />
    </v-modal>
  </div>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import JobExtra from '../interfaces/job-extra';

export default defineComponent({
  name: 'JobCard',
  props: {
    job: {
      type: Object as PropType<JobExtra>,
      required: true,
    },
  },
  emits: ['job-deleted'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localJob: cloneDeep(this.job) as JobExtra,
      standByModalOpened: false,
      editModalOpened: false,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'jobs',
      'currentAddress',
    ]),
    formattedSalary(): string {
      let returnValue = '';

      if (this.localJob.monthlySalary) {
        returnValue = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
          Number.parseInt(this.localJob.monthlySalary, 10),
        );
      }

      return returnValue;
    },
  },
  watch: {
    job(newJob: JobExtra | null): void {
      if (newJob) {
        this.onJobUpdated(newJob);
      }
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'updateJob',
      'deleteJob',
    ]),

    /**
     * When the user closes the "stand-by" modal, put the "active" model back.
     */
    cancelStandBy(): void {
      if (!Cookies.get('standByModalOpened')) {
        this.standByModalOpened = false;
        this.localJob.active = true;
      }
    },

    /**
     * This method is called when the user chooses to put on stand-by
     * the current address.
     */
    async standBy(): Promise<void> {
      await this.saveJob();

      // Set a session cookie to prevent the last inactive card
      // to be active again after the "stand-by" is set
      Cookies.set('standByModalOpened', 'true');

      this.standByModalOpened = false;

      // Remove the session cookie after one second
      setTimeout(() => {
        Cookies.remove('standByModalOpened');
      }, 1000);
    },

    /**
     * This method is called when the job is updated through the edit modal.
     */
    onJobUpdated(newJob: JobExtra | null): void {
      this.editModalOpened = false;

      if (newJob) {
        this.localJob = cloneDeep(newJob);
      }
    },

    /**
     * Do the actual save with the API, using the recruiter store.
     */
    async saveJob(): Promise<void> {
      try {
        await this.updateJob({
          uuid: this.localJob.uuid,
          data: {
            active: this.localJob.active,
            urgent: this.localJob.urgent,
          },
        });
      } catch {
        this.toast.error((this.$t('components.job_card.update_error') as string));
      }
    },

    /**
     * This method is called when the "active" switch is changed.
     * If the user is about to deactivate the last job, it should
     * display a modal telling the user this address is going
     * to be in stand by.
     */
    async updateActive(): Promise<void> {
      if (this.localJob.urgent && !this.localJob.active) {
        this.localJob.urgent = false;
      }

      const shouldDisplayStandByModal = !this.localJob.active
      && this
        .jobs
        .filter((job: JobExtra) => job.id !== this.localJob.id)
        .every((job: JobExtra) => !job.active);

      if (shouldDisplayStandByModal) {
        this.standByModalOpened = true;
      } else {
        await this.saveJob();
      }
    },
    /**
     * This method is called when the "urgent" switch is changed.
     * If the job is flagged has "urgent", but inactive, we
     * activate it automatically.
     */
    async updateUrgent(): Promise<void> {
      if (this.localJob.urgent && !this.localJob.active) {
        this.localJob.active = true;
      }

      await this.saveJob();
    },
    /**
     * This method is called when the user clicks on the thrash bin icon.
     */
    async removeJob(): Promise<void> {
      try {
      // Delete the job
        await this.deleteJob(this.localJob.uuid);

        // Trigger jobs refresh
        this.$emit('job-deleted');

        this.toast.success((this.$t('components.job_card.delete_success') as string));
      } catch {
        this.toast.error((this.$t('components.job_card.delete_error') as string));
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';

@include job-card();

.selected-values {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 34px;
  margin-top: 10px;
}

.value-badge {
  align-items: center;
  background-color: #d7d5d3;
  border-radius: 8px;
  color: #1d1d1f;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 4px 6px;
}

.job-card-cta {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  .btn {
    font-size: 14px;
    padding: 0;
  }
}

// Stand by modal
.stand-by-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 44px;
}

.stand-by-content {
  margin-bottom: 44px;
}

.stand-by-cta {
  display: flex;
  gap: $grid-gutter-width;
  justify-content: center;
}

.btn-update {
  font-weight: inherit;
}

.underline-text {
  text-decoration: underline;
}

.custom-switchs {
  display: flex;
  gap: 22px;
  margin-bottom: 8px;
}

.job-card-name {
  /* stylelint-disable */
  display: -webkit-box;
  line-height: 1.2em;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable */
}
</style>
