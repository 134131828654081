<template>
  <div class="application-information">
    <div v-if="application.documents.length > 0" class="application-documents">
      <h6>{{ $t('views.resume.documents') }}</h6>
      <a
        v-for="document in application.documents"
        :key="document.name"
        class="download-link d-flex align-items-center"
        target="_blank"
        rel="noopener"
        :href="document.file">
        <img src="@/images/svg/download-2.svg" alt="">
        <span class="mx-2 mt-2 text-blue">{{ document.name }}</span>
      </a>

      <hr class="divider">
    </div>

    <div class="application-job">
      <h6 v-if="application.job">{{ $t('views.resume.experience') }} {{ application.job.name }}</h6>
      <h6 v-else>{{ $t('views.resume.experience_without_job') }}</h6>

      <ul v-if="application.experience">
        <li>{{ application.experience.name }}</li>
      </ul>

      <div v-if="application.candidate.jobs.length > 0" class="last-jobs">
        <h6>{{ $t('views.resume.last_jobs') }}</h6>
        <ul>
          <li v-for="job in application.candidate.jobs" :key="job.id" class="text-s last-jobs-name">{{ job.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import Application from '../interfaces/application';

export default defineComponent({
  name: 'ProfileCardApplicationInformation',
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
    public: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    copyProfileLink(): void {
      const url = new URL(window.location.toString());
      navigator.clipboard.writeText(`${url.origin}/?application=${this.application.uuid}`);
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.card-body,
.application-information {
  color: $gray-900;
  margin-top: 0;
  position: relative;

  @include mq($from: desktop) {
    margin-top: 40px;
  }
}

.application-job {
  margin-top: 2rem;

  ul {
    padding-left: 15px;
  }
}

.download-link {
  text-decoration: none;
  word-break: break-all;
}

.divider {
  background-color: $gray-400;
}

.last-jobs {
  background-color: $white;
  border: 0;
  margin-bottom: 20px;

  @include mq($from: desktop) {
    border: 1px solid $gray-400;
    border-radius: 10px;
    padding: 20px;
  }

  ul {
    margin-bottom: 0;
  }
}
</style>
