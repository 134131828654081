<template>
  <div class="position-relative">
    <label for="searchbar" class="sr-only">{{ placeholder }}</label>
    <input
      id="searchbar"
      v-model="localValue"
      class="searchbar"
      type="text"
      :placeholder="placeholder"
      @input.prevent="update">
    <v-sprite icon="magnifier" color="currentColor" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchBar',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['onQueryUpdated', 'update:modelValue'],
  data() {
    return {
      localValue: this.modelValue as string,
    };
  },
  methods: {
    update(): void {
      this.$emit('update:modelValue', this.localValue);
      this.$emit('onQueryUpdated', this.localValue);
    },
  },
});

</script>

<style lang="scss">
@import '../../scss/config/config';

.svg-magnifier {
  bottom: 0;
  margin: auto 0;
  position: absolute;
  right: 30px;
  top: 0;
}

.searchbar {
  background-color: #574cda;
  border: 0;
  border-radius: 30px;
  box-sizing: border-box;
  color: $white;
  height: 50px;
  outline: $white;
  padding: 13px 57px 13px 20px;
  width: 100%;

  &::placeholder {
    color: $white;
  }

  .store-switch-list & {
    background-color: $white;
    box-shadow: 0 0 15px rgba(0, 0, 0, .15);
    color: $body-color;
    font-size: 14px;
    height: 42px;
    padding-bottom: 13px;
    padding-top: 13px;

    &::placeholder {
      color: $body-color;
      opacity: 1;
    }

    + .svg-magnifier {
      right: 15px;
    }
  }
}
</style>
