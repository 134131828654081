import { ActionContext } from 'vuex';

import AddressRegistration from '@/js/interfaces/address-registration';
import Information from '@/js/interfaces/information';
import MapboxAddress from '@/js/interfaces/mapbox-address';
import Price from '@/js/interfaces/price';
import Product from '@/js/interfaces/product';
import StripeLineItem from '@/js/interfaces/stripe.line-item';
import Subscription from '@/js/interfaces/subscription';
import State from '@/js/store/state';

// eslint-disable-next-line max-len,vue/max-len
const addressHasContent = (address: AddressRegistration) => (Object.keys(address.content).length > 0 && address.content.constructor === Object);

export interface SaveAddressPayload {
  index: number,
  address: AddressRegistration,
}

interface RegisterState {
  currentStep: number,
  subscription?: Subscription|null,
  price?: Price|null,
  addresses: Array<AddressRegistration>,
  products: Array<Product>,
  information: Information,
  informationValid: boolean,
}

const defaultInformation: Information = {
  lastName: '',
  firstName: '',
  email: '',
  password: '',
  passwordRepeat: '',
  recruiter: {
    company: '',
    manager: '',
    phoneNumber: '',
    address: {} as MapboxAddress,
  },
};

const defaultState: RegisterState = {
  currentStep: 1,
  subscription: null,
  price: null,
  addresses: [],
  products: [],
  information: defaultInformation,
  informationValid: false,
};

type Context = ActionContext<RegisterState, State>;

const mutations = {
  setCurrentStep(state: RegisterState, step: number) {
    state.currentStep = step;
  },

  setSubscription(state: RegisterState, subscription: Subscription) {
    state.subscription = subscription;
  },

  setPrice(state: RegisterState, price: Price) {
    state.price = price;
  },

  setProducts(state: RegisterState, products: Array<Product>) {
    state.products = products;
  },

  setAddresses(state: RegisterState, addresses: Array<AddressRegistration>) {
    state.addresses = addresses;
  },

  addAddress(state: RegisterState, address: AddressRegistration) {
    state.addresses.push(address);
  },

  saveAddress(state: RegisterState, payload: SaveAddressPayload) {
    state.addresses[payload.index] = payload.address;
  },

  deleteAddress(state: RegisterState, index: number) {
    state.addresses.splice(index);
  },

  setInformation(state: RegisterState, information: Information) {
    state.information = information;
  },

  setInformationValid(state: RegisterState, informationValid: boolean) {
    state.informationValid = informationValid;
  },
};

const actions = {
  // Step
  setCurrentStep(context: Context, step: number) {
    context.commit('setCurrentStep', step);
  },

  // Subscription
  setSubscription(context: Context, subscription: Subscription) {
    context.commit('setSubscription', subscription);
  },

  setPrice(context: Context, price: Price) {
    context.commit('setPrice', price);
  },

  setProducts(context: Context, products: Array<Product>) {
    context.commit('setProducts', products);
  },

  // Address
  addEmptyAddress(context: Context) {
    const address: AddressRegistration = {
      logo: '',
      qrcodeTown: '',
      name: '',
      content: {} as MapboxAddress,
      activitySector: 0,
      subscription: null,
    };

    context.commit('addAddress', address);
  },

  saveAddress(context: Context, payload: SaveAddressPayload) {
    context.commit('saveAddress', payload);
  },

  async deleteAddress(context: Context, index: number) {
    context.commit('deleteAddress', index);

    if (context.state.addresses.length <= 0) {
      await context.dispatch('addEmptyAddress');
    }
  },

  reset(context: Context) {
    context.commit('setInformation', defaultInformation);
    context.commit('setInformationValid', false);
    context.commit('setCurrentStep', 1);
    context.commit('setSubscription', null);
    context.commit('setPrice', null);
    context.commit('setAddresses', []);
  },

  setInformation(context: Context, information: Information) {
    context.commit('setInformation', information);
  },

  setInformationValid(context: Context, informationValid: boolean) {
    context.commit('setInformationValid', informationValid);
  },
};

interface Getters {
  canGoToStep2: boolean;
  subscriptions: Product[];
  goods: Product[];
  subscriptionTotal: number;
  total: number;
  lineItems: Array<StripeLineItem>;
}

const getters = {
  canGoToStep2: (state: RegisterState) => {
    const hasSubscription = !!state.subscription && !!state.price;

    const invalidAddresses = state.addresses.filter(
      (address: AddressRegistration) => address.name === ''
        || address.activitySector === '0'
        || !addressHasContent(address)
        || !address.qrcodeTown,
    );

    return hasSubscription && invalidAddresses.length === 0 && state.informationValid;
  },
  subscriptions: (state: RegisterState) => state.products.filter((product) => product.prices.length > 1),
  goods: (state: RegisterState) => state.products.filter((product) => product.prices.length === 1),
  subscriptionTotal: (state: RegisterState) => {
    let subscriptionTotal = 0;

    if (state.price) {
      subscriptionTotal = state.price.unitAmount * state.addresses.length;
    }

    return subscriptionTotal;
  },
  total: (state: RegisterState, getter: Getters) => getter.subscriptionTotal,
  lineItems: (state: RegisterState) => {
    const lineItems: Array<StripeLineItem> = [];

    lineItems.push({
      price: state.price?.id ?? '',
      quantity: state.addresses.length ?? 0,
    });

    return lineItems;
  },
};

const register = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default register;
