<template>
  <div>
    <nav class="brand-navigation">
      <ul class="brand-navigation-list list-unstyled">
        <li>
          <a
            href="#"
            class="brand-navigation-list-link"
            :class="{ active: currentTab === brandTabValues.Advantage }"
            @click.prevent="localSetCurrentTab(brandTabValues.Advantage)">
            {{ $t('components.brand_navigation.advantages') }}
          </a>
        </li>
        <li>
          <a
            href="#"
            class="brand-navigation-list-link"
            :class="{ active: currentTab === brandTabValues.Recruitment }"
            @click.prevent="localSetCurrentTab(brandTabValues.Recruitment)">
            {{ $t('components.brand_navigation.recruitment') }}
          </a>
        </li>
        <li>
          <a
            href="#"
            class="brand-navigation-list-link"
            :class="{ active: currentTab === brandTabValues.Media }"
            @click.prevent="localSetCurrentTab(brandTabValues.Media)">
            {{ $t('components.brand_navigation.media') }}
          </a>
        </li>
        <li>
          <a
            href="#"
            class="brand-navigation-list-link"
            :class="{ active: currentTab === brandTabValues.Values }"
            @click.prevent="localSetCurrentTab(brandTabValues.Values)">
            {{ $t('components.brand_navigation.values') }}
          </a>
        </li>
      </ul>
    </nav>

    <v-modal id="saveConfirmationModal" v-model="confirmModalOpened">
      <div class="p-2">
        <h5 class="text-center mb-4">{{ $t('views.brand.confirm_modal.title') }}</h5>
        <p class="text-center mb-4 steel-text">{{ $t('views.brand.confirm_modal.description') }}</p>
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-outline-primary btn-rounded m-2 cancel-logout-btn" @click.prevent="discard()">
            {{ $t('actions.cancel') }}
          </button>
          <button type="button" class="btn btn-primary m-2 logout-btn btn-rounded" @click.prevent="save()">
            {{ $t('actions.save') }}
          </button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { BrandTab } from '../store/modules/brand';

export default defineComponent({
  name: 'BrandNavigation',
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      nextTab: undefined as (BrandTab | undefined),
      confirmModalOpened: false,
      brandTabValues: BrandTab,
    };
  },
  computed: {
    ...mapState('brand', [
      'currentTab',
      'isUpdated',
      'selectedRecruitmentSteps',
    ]),
    ...mapState('recruiter', [
      'currentAddress',
    ]),
  },
  methods: {
    ...mapActions('brand', [
      'setCurrentTab',
      'setIsUpdated',
      'saveAdvantages',
      'setSelectedRecruitmentSteps',
      'saveRecruitmentSteps',
      'saveValues',
      'setIsUpdated',
      'saveMedias',
    ]),
    ...mapActions('recruiter', [
      'setCurrentAddress',
    ]),
    localSetCurrentTab(tab: BrandTab): void {
      if (this.isUpdated) {
        this.nextTab = tab;
        this.confirmModalOpened = true;
      } else {
        this.setCurrentTab(tab);
      }
    },
    async discard(): Promise<void> {
      this.setIsUpdated(false);
      this.setCurrentAddress(this.currentAddress);
      this.confirmModalOpened = false;
      this.setCurrentTab(this.nextTab);
    },
    async save(): Promise<void> {
      switch (this.currentTab) {
        case BrandTab.Advantage: {
          try {
            await this.saveAdvantages();
            this.setCurrentAddress(this.currentAddress);
            this.confirmModalOpened = false;
            this.toast.success((this.$t('components.brand_advantages.save.success') as string));
            this.setCurrentTab(this.nextTab);
          } catch {
            this.toast.error((this.$t('components.brand_advantages.save.error') as string));
          }

          break;
        }
        case BrandTab.Recruitment: {
          try {
            let selectedRecruitmentSteps = [...this.selectedRecruitmentSteps];
            selectedRecruitmentSteps = selectedRecruitmentSteps.filter((step) => step !== 0);
            await this.setSelectedRecruitmentSteps(selectedRecruitmentSteps);
            await this.saveRecruitmentSteps();
            this.setCurrentAddress(this.currentAddress);
            this.confirmModalOpened = false;
            this.setCurrentTab(this.nextTab);
            this.toast.success((this.$t('components.brand_recruitment.save.success') as string));
          } catch {
            this.toast.error((this.$t('components.brand_recruitment.save.error') as string));
          }

          break;
        }
        case BrandTab.Media: {
          try {
            await this.saveMedias();
            this.setCurrentAddress(this.currentAddress);
            this.confirmModalOpened = false;
            this.setCurrentTab(this.nextTab);
            this.toast.success((this.$t('components.brand_media.save.success') as string));
          } catch (error) {
            console.error(error);
            this.toast.error((this.$t('components.brand_media.save.error') as string));
          }

          break;
        }
        case BrandTab.Values: {
          try {
            await this.saveValues();
            this.setCurrentAddress(this.currentAddress);
            this.confirmModalOpened = false;
            this.setCurrentTab(this.nextTab);
            this.toast.success((this.$t('components.brand_values.save.success') as string));
          } catch {
            this.toast.error((this.$t('components.brand_values.save.error') as string));
          }

          break;
        }
        default:
        // No default
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import '~sass-mq/mq';

.brand-navigation {
  font-size: 13px;
  font-weight: 700;

  @include mq($from: tablet) {
    font-size: 22px;
  }
}

.brand-navigation-list {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-around;

  @include mq($from: tablet) {
    align-items: baseline;
    justify-content: space-between;
  }

  li {
    line-height: $headings-line-height;
    text-align: center;

    @include mq($from: tablet) {
      text-align: left;
    }
  }
}

.brand-navigation-list-link {
  opacity: .5;
  text-decoration: none;

  &.active {
    opacity: 1;
    text-decoration: underline;
  }
}
</style>
