import { ActionContext } from 'vuex';

import Address from '@/js/interfaces/address';
import State from '@/js/store/state';

export enum CompleteStep {
  SIRET,
  FORM,
  JOBS,
  COMPLETE,
}

interface CompleteState {
  step: CompleteStep,
  address: Address | null,
  addressQuery: string,
  loading: boolean,
}

const defaultState: CompleteState = {
  step: CompleteStep.SIRET,
  address: null,
  addressQuery: '',
  loading: false,
};

type Context = ActionContext<CompleteState, State>;

const mutations = {
  setStep(state: CompleteState, step: CompleteStep): void {
    state.step = step;
  },

  setAddress(state: CompleteState, address: Address): void {
    state.address = address;
  },

  setAddressQuery(state: CompleteState, addressQuery: string): void {
    state.addressQuery = addressQuery;
  },

  setLoading(state: CompleteState, loading: boolean): void {
    state.loading = loading;
  },
};

const actions = {
  /**
   * Set the current step in the navigation
   * @param context
   * @param step
   */
  setStep(context: Context, step: CompleteStep): void {
    context.commit('setStep', step);
  },

  /**
   * Set the current address
   * @param context
   * @param address
   */
  setAddress(context: Context, address: Address): void {
    context.commit('setAddress', address);
  },

  /**
   * Set the addressQuery
   * @param context
   * @param addressQuery
   */
  setAddressQuery(context: Context, addressQuery: string): void {
    context.commit('setAddressQuery', addressQuery);
  },

  /**
   * Set the loading
   * @param context
   * @param loading
   */
  setLoading(context: Context, loading: boolean): void {
    context.commit('setLoading', loading);
  },

  /**
   * Go to the previous step
   * @param context
   */
  goToPreviousStep(context: Context): void {
    const newStep = context.state.step - 1;
    context.commit('setStep', newStep);
  },
};

const getters = {};

const complete = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default complete;
