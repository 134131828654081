<template>
  <div>
    <button
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvas"
      aria-controls="offcanvas"
      class="d-block d-lg-none btn p-0 btn-offcanvas"
    >
      <v-sprite icon="burger-white" />
    </button>

    <div id="offcanvas" class="offcanvas offcanvas-start" tabindex="-1">
      <div class="offcanvas-header">
        <v-logo />
        <button
          type="button"
          class="btn-close text-white align-self-start"
          data-bs-dismiss="offcanvas" aria-label="Close" />
      </div>
      <div class="offcanvas-body">
        <v-app-navigation class="offcanvas-navigation" @close-menu="closeMenu" @open-modal="$emit('openModal')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppNavigationResponsive',
  emits: ['openModal'],

  data() {
    return {
      bsOffcanvas: null as (Offcanvas| null),
    };
  },
  mounted(): void {
    const offcanvas = document.querySelector('#offcanvas');
    if (offcanvas) {
      this.bsOffcanvas = new Offcanvas((offcanvas as Element));
    }
  },
  methods: {
    closeMenu(): void {
      this.bsOffcanvas?.hide();
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@include offcanvas();

.app-navigation {
  display: block;
}
</style>
