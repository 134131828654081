import Modal from 'bootstrap/js/dist/modal';

window.addEventListener('message', (event) => {
  if (event.data === 'applicationContentLoaded') {
    const applicationModalElement = document.querySelector('#application-modal') as HTMLElement;

    if (applicationModalElement) {
      const applicationModal = new Modal(applicationModalElement);
      applicationModal.show();
    }

    const applicationIframeElement = document.querySelector('#application-iframe') as HTMLIFrameElement;
    if (applicationIframeElement) {
      applicationIframeElement.height = '';
      applicationIframeElement.height = `${applicationIframeElement.contentWindow?.document.body.scrollHeight}px`;
    }
  }

  if (event.data === 'applicationNotFound') {
    const applicationModalElement = document.querySelector('#application-modal-not-found') as HTMLElement;

    if (applicationModalElement) {
      const applicationModal = new Modal(applicationModalElement);
      applicationModal.show();
    }
  }
});
