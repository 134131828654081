<template>
  <div class="order-sticker">
    <h4 class="order-sticker-label">{{ $t('components.manage_address.label_qr_code_sticker') }}</h4>
    <div
      v-if="localGoods"
      class="additional-address-products mb-3">
      <v-register-address-product
        v-for="product in localGoods"
        :key="product.id"
        :minimum-quantity="0"
        :product="product"
        :quantity="product.quantity"
        :address-index="0"
        @update:quantity="product.quantity = $event; updateSelectedProducts()" />
    </div>

    <div class="text-s">*&nbsp;{{ $t('views.register_step_2.shipping_info') }}</div>
    <div>
      <hr class="divider mt-2">
    </div>
    <div class="d-flex justify-content-center">
      <div class="order-sticker-actions">
        <div class="order-sticker-total-price fw-700">
          {{ totalWithoutTaxes / 100 }} € {{ $t('price.without_taxes') }}
        </div>

        <div>
          <button
            type="button"
            class="btn btn-primary d-flex align-items-center"
            :disabled="totalWithoutTaxes === 0"
            @click.prevent="redirectToPayment">
            <span v-if="isPaymentLoading" class="spinner-border text-white" role="status">
              <span class="visually-hidden">Loading...</span>
            </span>
            <span>{{ $t('components.manage_address.btn_qr_code_sticker') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapGetters, mapState } from 'vuex';

import Address from '../interfaces/address';
import PaginatedResult from '../interfaces/pagination';
import Price from '../interfaces/price';
import Product from '../interfaces/product';
import StripeLineItem from '../interfaces/stripe.line-item';
import Subscription from '../interfaces/subscription';

export default defineComponent({
  name: 'OrderSticker',
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
    subscription: {
      type: Object as PropType<Subscription | null>,
      default: null,
      required: false,
    },
    price: {
      type: Object as PropType<Price | null>,
      default: null,
      required: false,
    },
  },
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      localGoods: [] as Product[],
      isPaymentLoading: false,
      selectedProducts: [] as Product[],

    };
  },
  computed: {
    ...mapState('recruiter', [
      'user',
    ]),
    ...mapGetters('register', [
      'goods',
    ]),
    totalWithoutTaxes(): number {
      let total = 0;

      if (this.selectedProducts) {
        // eslint-disable-next-line max-len
        total = this.selectedProducts.reduce(
          (accumulator: number, product: Product) => accumulator + product.prices[0].unitAmount * (product.quantity ?? 0),
          0,
        );
      }

      return total;
    },
  },
  mounted() {
    window.ky.get('/api/products/').json().then(async (data: PaginatedResult<Product>) => {
      const products = data.results;
      await this.setProducts(products);
      this.localGoods = cloneDeep(this.goods);
    });
  },
  methods: {
    ...mapActions('recruiter', [
      'getProfile',
    ]),
    ...mapActions('register', [
      'setProducts',
    ]),

    async redirectToPayment(): Promise<void> {
      this.isPaymentLoading = true;

      const lineItems: Array<StripeLineItem> = [];

      for (const product of this.selectedProducts) {
        if (product.quantity) {
          lineItems.push({
            price: product.prices[0].id,
            quantity: product.quantity,
          });
        }
      }

      const payload = {
        lineItems,
        address: this.address.uuid,
        price: this.price,
        products: this.selectedProducts,
        subscription: this.subscription,
      };

      try {
        const data = await window.ky.post('/ecommerce/product-payment-link/', { json: payload }).json();

        if (data.url) {
          window.location.href = data.url;
        }
      } catch (error) {
        console.error(error);
        this.toast.error((this.$t('views.addresses.add_address_products_form.error') as string));
      } finally {
        this.isPaymentLoading = false;
      }
    },
    // eslint-disable-next-line class-methods-use-this
    addressHasContent(address: Address): boolean {
      return (Object.keys(address.content).length > 0 && address.content.constructor === Object);
    },

    updateSelectedProducts(): void {
      if (this.localGoods) {
        this.selectedProducts = this.localGoods.filter((product) => product.quantity !== null && product.quantity > 0);
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.order-sticker {
  background-color: $light;
  border-radius: 10px;
  margin: 30px 0;
  padding: 30px 35px;
}

.order-sticker-label {
  font-size: 22px;
  margin-bottom: $spacer * 2;
  text-align: center;
}

.order-sticker-actions {
  align-items: center;
  display: inline-grid;
  gap: $spacer;
  grid-template-columns: 80px 1fr;
  justify-content: center;
}

.order-sticker-qrcode {
  margin-bottom: 18px;
}

</style>
