<template>
  <div class="dashboard-navbar">
    <h2 class="app-subtitle">{{ $t('views.dashboard.my_jobs') }}</h2>

    <p>
      <a href="" class="add-job-link btn-icon" @click.prevent="modalOpened = true" @keydown.enter.prevent="modalOpened = true">
        <v-sprite class="plus-icon-white" icon="plus" />
        <v-sprite class="plus-icon-dark" icon="plus_dark" />
        {{ $t('views.dashboard.add_a_job') }}
      </a>
    </p>

    <div class="mb-3">
      <div v-for="job in jobs" :key="job.id" class="dashboard-job-card card text-body mb-2">
        <div class="card-body">
          {{ job.name }}
        </div>
      </div>
    </div>

    <v-modal :id="modalId" v-model="modalOpened">
      <v-new-job-form
        v-model="modalOpened"
        :jobs="jobs" />
    </v-modal>

    <div class="d-flex justify-content-center align-items-center mt-4 mt-md-1">
      <router-link :to="{ name: routeNames.jobs }" class="btn btn-primary btn-icon btn-keep-size dashboard-navbar-btn">
        <v-sprite icon="gear" />
        {{ $t('views.dashboard.manage_my_jobs') }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'DashboardNavbar',
  data() {
    return {
      modalOpened: false,
      modalId: 'newJobModal',
    };
  },
  computed: {
    ...mapState('recruiter', [
      'jobs',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.dashboard-navbar {
  background-color: $white;
  border-radius: $spacer;
  padding: $spacer;

  @include mq($from: tablet) {
    background-color: transparent;
    padding: 0;
  }
}

.app-subtitle,
.add-job-link {
  color: $body-color;

  @include mq($from: desktop) {
    color: $white;
  }

  .plus-icon-white {
    display: none;

    @include mq($from: desktop) {
      display: block;
    }
  }

  .plus-icon-dark {
    display: block;

    @include mq($from: desktop) {
      display: none;
    }
  }
}

.dashboard-job-card {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);

  .card-body {
    padding: $spacer;
  }
}

.dashboard-navbar-btn {
  font-size: 15px;
}
</style>
