<template>
  <div class="input-group has-validation position-relative">
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <input
      :id="id"
      :type="passwordFieldType"
      :name="name"
      class="form-control"
      :class="inputClass"
      :value="modelValue"
      placeholder="Entrez votre mot de passe"
      required @input="$emit('update:modelValue', ($event as HTMLInputEvent)?.target?.value)"
    >

    <div class="icon" @click.prevent="togglePasswordVisibility()" @keydown.enter.prevent="togglePasswordVisibility()">
      <v-sprite v-if="passwordFieldType === 'password'" icon="eye" />
      <v-sprite v-if="passwordFieldType === 'text'" icon="eye-hide" />
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PasswordField',
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    inputClass: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      passwordFieldType: 'password',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
});
</script>

<style lang="scss" scoped>
.input-group .icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.input-group input {
  border-bottom-right-radius: 15px !important;
  border-top-right-radius: 15px !important;
  width: 100%;
}

.svg-eye,
.svg-eye-hide {
  position: relative;
  top: 3px;
}
</style>
