<template>
  <div>
    <form @submit.prevent="submitAskAddressForm">
      <div class="form-group form-group-required" :class="{ 'is-invalid': number && v.number.$invalid }">
        <label class="form-label" for="number">{{ $t('components.ask_address_form.number') }}</label>
        <input
          :id="'number'"
          v-model.trim="number"
          type="number"
          :name="'number'"
          class="form-control"
          :class="{ 'is-invalid': number && v.number.$invalid }">
        <div v-if="number && !v.number.required" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
      </div>

      <div class="form-group form-group-required" :class="{ 'is-invalid': email && v.email.$invalid }">
        <label class="form-label" for="email">{{ $t('components.ask_address_form.email') }}</label>
        <input
          :id="'email'"
          v-model.trim="email"
          type="email"
          :name="'email'"
          class="form-control"
          :class="{ 'is-invalid': email && v.email.$invalid }">
        <div v-if="email && !v.email.required" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
        <div v-if="email && !v.email.email" class="invalid-feedback">{{ $t('form.validation.email') }}</div>
      </div>

      <div class="form-group" :class="{ 'is-invalid': message && v.message.$invalid }">
        <label class="form-label" for="message">{{ $t('components.ask_address_form.message') }}</label>
        <textarea
          :id="'message'"
          v-model.trim="message"
          maxlength="500"
          :name="'message'"
          class="form-control"
          :placeholder="$t('components.ask_address_form.placeholder.message')"
          :class="{ 'is-invalid': message && v.message.$invalid }" />
        <div v-if="message && !v.message.maxLength" class="invalid-feedback">{{ $t('form.validation.required') }}</div>
        <div v-if="!message" class="form-text text-end mt-2">{{ messageMaxLength }} caractères maximum</div>
        <div v-if="message" class="form-text text-end mt-2">{{ messageMaxLength - message.length }} caractères restants</div>
      </div>

      <div class="d-flex justify-content-center gap-3">
        <button type="button" class="btn btn-outline-primary btn-rounded" @click.prevent="$emit('cancel')">
          {{ $t('actions.cancel') }}
        </button>
        <button class="btn btn-primary btn-rounded" type="submit" :disabled="v.$invalid">
          {{ $t('actions.send') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { email as vaEmail, minLength, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'AskAddressForm',
  emits: ['cancel', 'on-submit'],
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      number: 1,
      email: '',
      message: '',
      messageMaxLength: 500,
    };
  },
  computed: {
    ...mapState('recruiter', [
      'user',
      'parentAddress',
    ]),
  },
  mounted(): void {
    this.email = this.user.email;
  },
  validations() {
    return {
      number: { required },
      email: { required, vaEmail },
      message: { minLength: minLength(500) },
    };
  },
  methods: {
    async submitAskAddressForm(): Promise<void> {
      try {
        const payload = { number: this.number, email: this.email, message: this.message };
        await window.ky.post(`/api/addresses/${this.parentAddress.uuid}/ask/`, { json: payload }).json<void>();
        this.$emit('on-submit');
        this.toast.success((this.$t('components.ask_address_form.success') as string));
      } catch {
        this.toast.error((this.$t('components.ask_address_form.error') as string));
      }
    },
  },
});
</script>
