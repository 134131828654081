<template>
  <div class="register-summary">
    <h2 class="text-xxl text-primary fw-700 register-summary-title">{{ $t('components.register_summary.title') }}</h2>

    <div v-if="price" class="register-summary-subscription">
      <div class="fw-700 mb-2">
        {{ $t('components.register_summary.subscription_s') }} : <span>{{ cardTitle }}</span>
      </div>

      <p v-for="(product, index) in price.freeProducts" :key="index" class="subscription-bonus text-s mb-1">
        + {{ $t('components.subscription_card.offered_product', {
          quantity: product.offeredQuantity,
          name: product.offeredProduct.name,
          description: product.offeredProduct.description,
        }) }}
      </p>
    </div>

    <slot name="before-total" />

    <div class="register-summary-detail total">
      <div class="d-flex align-items-center justify-content-between">
        <div><strong>{{ $t('components.register_summary.total') }}</strong></div>
        <div class="register-summary-detail-total-price">
          <strong>{{ total / 100 }}€ {{ $t('price.without_taxes') }}</strong>
        </div>
      </div>
    </div>

    <div class="register-summary-actions">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

export default defineComponent({
  name: 'RegisterSummary',
  computed: {
    ...mapGetters('register', [
      'subscriptionTotal',
      'subtotal',
      'total',
    ]),
    ...mapState('register', [
      'price',
      'addresses',
    ]),
    intervalInMonth(): number {
      let intervalInMonth = 0;

      if (this.price && this.price.recurring) {
        if (this.price.recurring.interval === 'month') {
          intervalInMonth = this.price.recurring.intervalCount;
        } else if (this.price.recurring.interval === 'year') {
          intervalInMonth = this.price.recurring.intervalCount * 12;
        }
      }

      return intervalInMonth;
    },
    cardTitle() {
      switch (this.intervalInMonth) {
        case 1: {
          return this.$t('components.subscription_card.paying_duration_1_title');
        }
        case 12: {
          return this.$t('components.subscription_card.paying_duration_12_title');
        }
        default: {
          return `${this.intervalInMonth} ${this.$t('components.subscription_card.month')}`;
        }
      }
    },
  },
});

</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-summary {
  color: $dark-blue;
  position: sticky;
  top: 0;
}

.register-summary-subscription {
  border-bottom: 1px solid $gray-900;
  padding: $spacer 0;
}

.register-summary-detail {
  border-bottom: 1px solid $gray-900;
  padding: $spacer 0;

  .address-name {
    color: $dark-blue;
    text-decoration: none;
  }

  .icon {
    margin-left: auto;
    margin-right: 10px;
  }
}

.register-summary-detail.total {
  border-color: transparent;
}

.register-summary-detail-total-price {
  color: $dark-blue;
  font-size: 16px;
  margin-left: auto;
  text-align: right;
}

.register-summary-detail-price {
  color: $dark-blue;
  margin-left: 10px;
}

.register-summary-actions {
  display: grid;
  margin-bottom: 40px;

  @include mq($from: desktop) {
    margin-top: 40px;
  }
}

.register-summary-product {
  color: $gray-900;
}

.subscription-bonus {
  color: $gray-600;
  font-style: italic;
}
</style>
