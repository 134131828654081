// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let grecaptcha: any;

interface FormData {
  name: string;
  email: string;
  phone: string;
  project: string;
  captcha: string;
}

function displaySuccessMessage(alert: HTMLElement) {
  alert.classList.remove('alert-danger');
  alert.classList.add('alert-success');
  // eslint-disable-next-line no-param-reassign
  alert.textContent = 'Message envoyé avec succès';
}

function displayAlertMessage(alert: HTMLElement) {
  alert.classList.remove('alert-success');
  alert.classList.add('alert-danger');
  // eslint-disable-next-line no-param-reassign
  alert.textContent = 'Erreur pendant l\'envoi du formulaire';
}

function resetForm(form: HTMLFormElement) {
  Array.prototype.forEach.call(document.querySelectorAll('.form-control'), (element: HTMLInputElement) => {
    // eslint-disable-next-line no-param-reassign
    element.value = '';
  });

  form.classList.remove('was-validated');
}

function disableButton() {
  const button = document.querySelector('#contact-form-submit') as HTMLButtonElement;
  button.disabled = true;
  setTimeout(() => {
    button.disabled = false;
  }, 3000);
}

function resetCaptcha() {
  const captchaInput = document.querySelector('#id_captcha') as HTMLInputElement;
  if (captchaInput) {
    grecaptcha.reset();
    grecaptcha
      .execute(captchaInput.dataset.sitekey, { action: 'homepage' })
      .then((token: string) => {
        // Use the new token
        captchaInput.value = token;
      });
  }
}

// Function to submit the form data using Fetch
function submitForm(form: HTMLFormElement, data: FormData) {
  const alert = document.querySelector('#contact-form-alert') as HTMLElement;
  fetch('/contact/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      alert.classList.remove('d-none');

      if (response.ok) {
        const body = await response.json();
        if (body.success) {
          displaySuccessMessage(alert);
          resetForm(form);
          disableButton();
        } else {
          displayAlertMessage(alert);
        }
      } else {
        displayAlertMessage(alert);
      }

      resetCaptcha();
    })
    .catch(() => {
      displayAlertMessage(alert);
      resetCaptcha();
    });
}

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('#contact-form') as HTMLFormElement;

  if (!form) {
    return;
  }

  // Add an event listener to the form's submit event
  form.addEventListener('submit', (event) => {
    event.preventDefault();

    if (!form.checkValidity()) {
      event.stopPropagation();
    }

    form.classList.add('was-validated');

    const formData = new FormData(form);

    // Convert the FormData to a plain object
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const data: FormData = Object.fromEntries(formData.entries());
    submitForm(form, data);
  });
});
