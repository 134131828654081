<template>
  <div class="register-address-product">
    <div class="">
      <h3 class="register-address-product-name text-xl fw-700">{{ product.name }}&nbsp;<span class="text-s">*</span></h3>
      <p class="register-address-product-description">
        {{ product.description }}
        <br>
        {{ $t('components.register_address_product.unit_price') }}
        {{ (price / 100) }}€
        {{ $t('price.without_taxes') }}
      </p>
    </div>

    <div class="register-address-product-price">
      <div class="form-group">
        <label :for="inputName" class="sr-only">{{ product.description }}</label>
        <select
          :id="inputName"
          class="form-control"
          :name="inputName"
          @change="onQuantityChange($event as HTMLInputEvent)">
          <option v-for="(i, index) in range" :key="index" :value="i" :selected="i === quantity">
            {{ i }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import slug from 'slug';
import { defineComponent, PropType } from 'vue';

import { HTMLInputEvent } from '../interfaces/events';
import Product from '../interfaces/product';

export default defineComponent({
  name: 'RegisterAddressProduct',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    quantity: {
      type: Number,
      default: undefined,
      required: false,
    },
    addressIndex: {
      type: Number,
      required: true,
    },
    minimumQuantity: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:quantity'],
  computed: {
    inputName(): string {
      return `address_${slug(this.product.name)}_quantity${this.addressIndex}`;
    },
    range(): number[] {
      const length = 11;
      return Array.from({ length }, (_, index) => index);
    },
    price(): number {
      let price = 0;

      if (this.product.prices && this.product.prices.length > 0) {
        price = this.product.prices[0].unitAmount; // Get the first price
      }

      return price;
    },
  },
  mounted() {
    this.$emit('update:quantity', this.minimumQuantity);
  },
  methods: {
    onQuantityChange(event: HTMLInputEvent): void {
      if (event.target) {
        const quantity = Number.parseInt(event.target.value, 10);
        this.$emit('update:quantity', quantity);
      }
    },
  },
});

</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../../scss/config/config';

.register-address-product {
  display: flex;
  flex-direction: column;
  gap: $spacer;
  justify-content: space-between;

  .form-control {
    margin-top: auto;
  }
}

.register-address-product-name {
  color: #000;
  font-size: 18px;
  margin-bottom: 0;
}

.register-address-product-description {
  font-size: 14px;
  margin-bottom: 0;
  min-width: 140px;
}

.register-address-product-price {
  min-width: 140px;

  .form-control {
    width: 100%;
  }
}
</style>
