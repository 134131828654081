<template>
  <div>
    <h4>{{ $t('views.account.invoices.title') }}</h4>
    <div class="table-responsive">
      <table class="order-list table table-striped">
        <thead>
          <tr>
            <th>{{ $t('views.account.invoices.invoice_number') }}</th>
            <th class="d-none d-sm-table-cell" />
            <th class="d-none d-sm-table-cell">{{ $t('views.account.invoices.address') }}</th>
            <th class="d-none d-sm-table-cell">{{ $t('views.account.invoices.date') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in invoices" :key="index">
            <td>{{ invoice.invoiceNumber }}</td>
            <td class="d-none d-sm-table-cell">
              {{ $t(`views.account.invoices.billing_reasons.${invoice.billingReason}`)
                ? $t(`views.account.invoices.billing_reasons.${invoice.billingReason}`) : '' }}
            </td>
            <td class="d-none d-sm-table-cell">
              <div v-for="(address, addressIndex) in invoice.addresses" :key="addressIndex">
                <div>{{ address.name }}</div>
              </div>
            </td>
            <td class="d-none d-sm-table-cell">{{ invoice.createdAt }}</td>
            <td>
              <a
                v-if="invoice.isTreated"
                href="#"
                class="btn btn-sm btn-primary"
                @click.prevent="downloadInvoice(invoice.id)">
                {{ $t('actions.download') }}
              </a>
              <span v-else>{{ $t(`views.account.invoices.invoice_not_treated`) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { saveAs } from 'file-saver';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';

import Invoice from '../interfaces/invoice';

export default defineComponent({
  name: 'InvoicesList',
  props: {
    invoices: {
      type: Object as PropType<Invoice[]>,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async downloadInvoice(invoiceId: number): Promise<void> {
      try {
        const response = await window.ky.get(`/api/invoices/${invoiceId}/download/`);
        let filename = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.includes('attachment')) {
          const filenameRegex = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches && matches[1]) {
            filename = matches[1].replaceAll(/["']/g, '');
          }
        }
        const blob = await response.blob();
        saveAs(blob, filename);
      } catch {
        this.toast.error((this.$t('components.download_invoice.error') as string));
      }
    },
  },
});
</script>
