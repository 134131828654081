<template>
  <form class="register-address-form" action="">
    <div class="form-group">
      <label class="form-label" :for="`address_name${index}`">{{ $t('components.register_address.name.label') }}</label>
      <input
        :id="`address_name${index}`"
        v-model="localAddress.name"
        class="form-control"
        type="text"
        name="address_name"
        maxlength="18"
        :placeholder="$t('components.register_address.name.placeholder')"
        @change.prevent="saveAddress({ address: localAddress, index })">
    </div>

    <div class="form-group">
      <label for="logo" class="form-label">{{ $t('components.register_address.logo') }}</label>
      <div class="d-flex gap-2 align-items-center">
        <div>
          <input
            id="logo"
            type="file"
            class="form-control"
            name="logo"
            accept="image/*"
            :class="{ 'is-invalid': fileSizeError || fileFormatError }"
            @change="onFileChange($event as HTMLInputEvent)">
          <small v-if="fileSizeError" class="invalid-feedback">{{ $t('form.validation.file_size') }}</small>
          <small v-if="fileFormatError" class="invalid-feedback">{{ $t('form.validation.image_file_format') }}</small>
        </div>
        <small class="form-text text-xs text-muted">{{ $t('form.image.format') }}<br>{{ $t('form.image.max_size') }}</small>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label" :for="`address_content${index}`">{{ $t('components.register_address.content.label') }}</label>
      <vue-bootstrap-autocomplete
        :id="`address_content${index}`"
        v-model="addressQuery"
        class="mb-1"
        :max-matches="5"
        :show-all-results="true"
        :data="mapboxAddresses"
        :serializer="(item: MapboxAddress) => item.place_name"
        :placeholder="$t('components.register_address.content.placeholder')"
        @hit="onSelectAddress($event)"
        @input="lookupAddressHandler"
      />
      <small class="form-text text-muted">{{ $t('form.helper.address') }}</small>
      <small class="text-black-50">{{ $t('components.account_form.address_helper') }}</small>
    </div>

    <v-activity-sector-field
      :address="localAddress"
      :index="index"
      @on-select-activity-sector="onSelectActivitySector"
    />

    <div class="form-group">
      <label class="form-label" :for="`address_qrcode_town${index}`">{{ $t('components.register_address.qrcode_town.label') }}</label>
      <input
        :id="`address_qrcode_town${index}`"
        v-model="localAddress.qrcodeTown"
        class="form-control"
        type="text"
        name="address_qrcode_town"
        maxlength="18"
        :placeholder="$t('components.register_address.qrcode_town.placeholder')"
        @change.prevent="saveAddress({ address: localAddress, index })">
    </div>

    <!-- No more qr code in the register process, but it will change, I'm sure
    <h3 class="text-l mt-4 mb-3 fw-700">{{ $t('components.register_address.additional_order') }}</h3>
    <div class="register-address-products mb-3">
      <v-register-address-product
        v-for="product in localAddress.products"
        :key="product.id"
        :minimum-quantity="['Caisse', 'Vitrine'].includes(product.name) ? 1 : 0"
        :product="product"
        :quantity="product.quantity"
        :address-index="index"
        @update:quantity="product.quantity = $event; saveAddress({address: localAddress, index})" />
    </div>
    -->
  </form>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { cloneDeep, DebouncedFunc, throttle } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import ActivitySector from '../interfaces/activity-sector';
import AddressRegistration from '../interfaces/address-registration';
import { HTMLInputEvent } from '../interfaces/events';
import MapboxAddress, { MapboxAddressContext } from '../interfaces/mapbox-address';
import { searchMapboxAddress } from '../services/mapbox';

export default defineComponent({
  name: 'RegisterAddress',
  props: {
    address: {
      type: Object as PropType<AddressRegistration>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    const v = useVuelidate();
    return { toast, v };
  },
  data() {
    return {
      localAddress: cloneDeep(this.address) as AddressRegistration,
      fileSizeError: false,
      fileFormatError: false,
      addressQuery: '',
      mapboxAddress: {} as MapboxAddress,
      mapboxAddresses: [] as MapboxAddress[],
      lookupAddressHandler: throttle(this.searchAddress, 1000) as DebouncedFunc<() => void>,
    };
  },
  computed: {
    ...mapState('register', [
      'addresses',
    ]),
  },
  mounted() {
    if (Object.keys(this.localAddress.content).length > 0 && this.localAddress.content.constructor === Object) {
      this.addressQuery = this.localAddress.content.place_name;
    }
    this.localAddress = cloneDeep(this.address);

    // To avoid API error if the user doesn't upload a logo
    if (!this.localAddress.logo) {
      this.localAddress.logo = null;
      const localAddress = cloneDeep(this.localAddress);
      this.saveAddress({ address: localAddress, index: this.index });
    }
  },
  methods: {
    ...mapActions('register', [
      'saveAddress',
      'deleteAddress',
    ]),

    async searchAddress(): Promise<void> {
      if (this.addressQuery && this.addressQuery.length > 2) {
        const data = await searchMapboxAddress(this.addressQuery);
        this.mapboxAddresses = data.suggestions;
      } else {
        this.mapboxAddresses = [];
      }
    },

    /**
   * To prevent direct store mutation, we need to cloneDeep the address
   */
    saveLocalAddress(): void {
      const localAddress = cloneDeep(this.localAddress);
      this.saveAddress({ address: localAddress, index: this.index });
    },

    onSelectAddress(selectedAddress: MapboxAddress): void {
      this.addressQuery = selectedAddress.place_name;
      this.mapboxAddress = selectedAddress;
      this.mapboxAddresses = [];
      this.localAddress = {
        ...this.localAddress,
        content: this.mapboxAddress,
      };

      if (this.localAddress.content.context) {
        this.localAddress.content.context.map((item: MapboxAddressContext) => {
          if (item.id.split('.')[0] === 'place') {
            this.localAddress = {
              ...this.localAddress,
              qrcodeTown: item.text.length > 18 ? `${item.text.slice(0, 18)}` : item.text,
            };
          }
          return true;
        });
      }

      this.saveAddress({ address: cloneDeep(this.localAddress), index: this.index });
    },

    onSelectActivitySector(activitySector: ActivitySector): void {
      this.localAddress.activitySector = activitySector.id;
      const localAddress = cloneDeep(this.localAddress);
      this.saveAddress({ address: localAddress, index: this.index });
    },

    onFileChange(e: HTMLInputEvent | DragEvent): void {
      const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
      if (!files?.length) return;

      if (files[0].size > 2_097_152) {
        this.fileSizeError = true;
        return;
      }

      if (!files[0].type.startsWith('image/') || files[0].type.startsWith('image/svg')) {
        this.fileFormatError = true;
        return;
      }

      this.fileSizeError = false;
      this.fileFormatError = false;

      this.createImage(files[0]);
    },

    createImage(file: Blob) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.localAddress.logo = (reader.result as string);
        const localAddress = cloneDeep(this.localAddress);
        this.saveAddress({ address: localAddress, index: this.index });
      });
      reader.readAsDataURL(file);
    },
  },
});

</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../../scss/config/config';
@import 'sass-mq/mq';

.register-address-form {
  margin: 0 0 $grid-gutter-width;
}

.divider {
  background-color: $gray-900;
  opacity: 1;
}

.register-address-products {
  display: grid;
  grid-gap: math.div($grid-gutter-width, 2);
  grid-template-columns: 1fr;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-label {
  color: $dark-blue;
  font-size: 16px;
  font-weight: 600;
}
</style>
