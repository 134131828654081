<template>
  <div class="candidate-information-card card">
    <div class="card-body text-center">
      <div class="candidate-avatar">
        <img v-if="avatar" :src="avatar" :alt="`${firstName} ${lastName}`" class="candidate-avatar-img">
        <span v-else class="text-blue empty-avatar-text">{{ userNameFirstLetters }}</span>
      </div>

      <h3 class="candidate-name mb-0 mt-1">
        {{ `${firstName} ${lastName}` }}
      </h3>

      <div class="mb-3">
        <span class="candidate-age">{{ age }} ans</span>
        <span class="candidate-address">{{ address }}</span>
      </div>

      <a :href="mailto" class="btn btn-primary btn-rounded btn-icon text-center mb-3">
        <img src="@/images/mail.svg" alt="">
        <span class="candidate-information-card-email">{{ email }}</span>
      </a>

      <a :href="tel" class="btn btn-primary btn-rounded btn-icon text-center">
        <img src="@/images/call.svg" alt="">
        {{ phoneNumber }}
      </a>

      <button v-if="!public" type="button" class="application-favorite" @click.prevent="toggleFavorite">
        <v-sprite v-if="!localFavorite" icon="heart" color="#9DA9C3" />
        <v-sprite v-if="localFavorite" icon="heart" color="#ff0e8d" />
      </button>

      <div v-if="public" class="application-favorite application-favorite-public">
        <v-sprite v-if="!localFavorite" icon="heart" color="#9DA9C3" />
        <v-sprite v-if="localFavorite" icon="heart" color="#ff0e8d" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';

import { getUserNameFirstLetters } from '../services/utils';

export default defineComponent({
  name: 'ProfileCardCandidateInformation',
  props: {
    uuid: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    age: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    favorite: {
      type: Boolean,
      required: true,
    },
    public: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localFavorite: this.favorite as Boolean,
    };
  },
  computed: {
    mailto(): string {
      return `mailto:${this.email}`;
    },

    tel(): string {
      return `tel:${this.phoneNumber}`;
    },

    userNameFirstLetters(): string {
      return getUserNameFirstLetters(this.firstName, this.lastName);
    },
  },
  methods: {
    async toggleFavorite(): Promise<void> {
      this.localFavorite = !this.localFavorite;

      try {
        await window.ky.patch(`/api/applications/${this.uuid}/`, {
          json: {
            favorite: this.localFavorite,
          },
        }).json();
      } catch {
        this.toast.error((this.$t('components.address_card.update.error') as string));
      }
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.candidate-age::after {
  content: ' • ';
}

.candidate-information-card {
  background-color: $light;

  .btn {
    text-align: center;
    width: 100%;

    @include mq($from: desktop) {
      text-align: left;
      width: auto;
    }
  }

  .btn-icon {
    img {
      max-height: 16px;
    }
  }
}

.candidate-information-card-email {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include mq($from: desktop) {
    max-width: 140px;
  }
}

.candidate-avatar {
  align-items: center;
  border: 3px solid $white;
  border-radius: 63px;
  display: inline-flex;
  height: 63px;
  justify-content: center;
  overflow: hidden;
  width: 63px;
}

.candidate-avatar-img {
  border: 3px solid $white;
  height: 63px;
  object-fit: cover;
  width: 63px;
}

.application-favorite {
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>
