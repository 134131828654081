<template>
  <div class="card profile-card" :class="{ 'public-profile-card': public }">
    <div class="card-body">
      <div v-if="!public" class="application-copy" @click.prevent="copyProfileLink" @keyup.prevent="copyProfileLink()">
        <button type="button" class="application-copy-btn">{{ $t('views.resume.copy_profile_link') }}</button>
        <div class="application-copy-addon">
          <v-sprite v-if="!copied" icon="copy" />
          <v-sprite v-if="copied" icon="check" />
        </div>
      </div>
      <div class="profile-card-header mb-3">
        <profile-card-candidate-information
          :uuid="application.uuid"
          :avatar="application.candidate.avatar"
          :first-name="application.user.firstName"
          :last-name="application.user.lastName"
          :age="application.candidate.age"
          :address="application.candidate.address.context[2].text"
          :email="application.user.email"
          :phone-number="application.candidate.phoneNumber"
          :favorite="application.favorite"
          :public="public"
        />
        <profile-card-application-information
          :application="application"
          :public="public"
        />
      </div>

      <div class="row">
        <div class="col-sm mb-3">
          <h6>{{ $t('views.resume.degrees') }}</h6>
          <div class="d-flex align-items-start degrees">
            <v-sprite icon="level" />
            <div class="degree-list">{{ degreeList }}</div>
          </div>
        </div>
        <div class="col-sm mb-3">
          <h6>{{ $t('views.resume.languages') }}</h6>
          <div v-for="language in application.candidate.languages" :key="language.id" class="d-flex align-items-center">
            <img class="icon flag-icon" :src="flagUrl(language.country)" alt="">
            <div>{{ language.name }}</div>
          </div>
        </div>
        <div v-if="application.candidate.licences.length > 0" class="col-sm mb-3">
          <h6>{{ $t('views.resume.licences') }}</h6>
          <div v-for="licence in application.candidate.licences" :key="licence.id" class="d-flex align-items-start">
            <img class="icon" :src="licence.icon" alt="">
            <div>
              {{ licence.name }}
              <span v-if="cacesList && licence.name === 'CACES'">({{ cacesList }})</span>
            </div>
          </div>
        </div>
        <div v-if="application.candidate.locomotions.length > 0" class="col-sm mb-3">
          <h6>{{ $t('views.resume.locomotion') }}</h6>
          <div v-for="locomotion in application.candidate.locomotions" :key="locomotion.id" class="d-flex align-items-center">
            <div class="locomotion-icon-wrapper">
              <img class="icon" :src="locomotion.icon" alt="">
            </div>
            <div>{{ locomotion.name }}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div v-for="(skills, index) in skillList" :key="index" class="col-sm-4">
          <h6>{{ index }}</h6>
          <ul class="skill-list">
            <li v-for="skill in skills" :key="skill.id">{{ skill.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import Application from '../interfaces/application';
import Caces from '../interfaces/caces';
import Degree from '../interfaces/degree';
import { Skill } from '../interfaces/skill';

interface SkillsGroupedByCategory {
  [name: string]: Skill[];
}

interface SkillCategoryPositions {
  [name: string]: number;
}

export default defineComponent({
  name: 'ProfileCard',
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
    public: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    degreeList() {
      const degrees = this.application.candidate.degrees.map((degree: Degree) => degree.name).join(', ');
      return degrees.charAt(0).toUpperCase() + degrees.slice(1);
    },

    cacesList() {
      return this.application.candidate.caces.map((c: Caces) => c.name).join(', ');
    },

    skillList() {
      const positions: SkillCategoryPositions = {};

      // eslint-disable-next-line max-len,vue/max-len
      const skillsGroupedByCategory: SkillsGroupedByCategory = this.application.candidate.skills.reduce((previousSkills: SkillsGroupedByCategory, currentSkill: Skill) => {
        const skills: SkillsGroupedByCategory = previousSkills;
        const category = currentSkill.parentCategory ?? currentSkill.category;
        skills[category] = [...skills[category] || [], currentSkill];
        positions[category] = currentSkill.categoryOrder;
        return skills;
      }, {});

      // Sort the keys of skillsGroupedByCategory based on positions
      const sortedKeys = Object.keys(skillsGroupedByCategory).sort((a, b) => positions[a] - positions[b]);

      // Create a new object with sorted keys
      const sortedSkillsGroupedByCategory: SkillsGroupedByCategory = {};
      for (const key of sortedKeys) {
        sortedSkillsGroupedByCategory[key] = skillsGroupedByCategory[key];
      }

      // Sort the skills within each category
      for (const key of Object.keys(sortedSkillsGroupedByCategory)) {
        sortedSkillsGroupedByCategory[key] = sortedSkillsGroupedByCategory[key].sort((a: Skill, b: Skill) => (b.name > a.name ? -1 : 1));
      }

      return sortedSkillsGroupedByCategory;
    },
  },
  methods: {
    copyProfileLink(): void {
      const url = new URL(window.location.toString());
      navigator.clipboard.writeText(`${url.origin}/?application=${this.application.uuid}`);
      this.copied = true;
    },
    // eslint-disable-next-line class-methods-use-this
    flagUrl(image: string) {
    // eslint-disable-next-line unicorn/prefer-module
      const images = require.context('../../images/flags', false, /\.svg$/);
      return images(`./${image}.svg`);
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.application-copy {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: inherit;

  @include mq($from: desktop) {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.application-copy-btn {
  align-items: center;
  background-color: $medium-blue;
  border: 0;
  border-radius: 14px 0 0 14px;
  color: $primary;
  display: flex;
  flex: 1;
  font-size: 14px;
  height: 26px;
  max-width: 154px;
  padding: 0 8px;
}

.application-copy-addon {
  align-items: center;
  background-color: $primary;
  border-bottom-right-radius: 14px;
  border-top-right-radius: 14px;
  color: $white;
  display: flex;
  height: 26px;
  justify-content: center;
  width: 30px;
}

.profile-card {
  background-color: $white;
  color: $gray-900;

  @media print {
    box-shadow: none;

    .card-body {
      padding: 0;
    }
  }

  .degrees {
    margin-top: 10px;

    .degree-list {
      padding-left: 10px;
    }
  }

  .skill-list {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  .icon,
  .flag-icon {
    margin-right: 9px;
    max-width: 25px;
  }

  .locomotion-icon-wrapper {
    text-align: center;
    width: 33px;

    .icon {
      max-height: 30px;
      max-width: 30px;
    }
  }

  .flag-icon {
    border-radius: 4px;
    width: 22px;
  }
}

.profile-card-header {
  display: grid;
  gap: $grid-gutter-width;

  @include mq($from: tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.public-profile-card {
  border-radius: 0;
  box-shadow: none;

  .card-body {
    padding: 1rem;
  }
}
</style>
