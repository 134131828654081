<template>
  <div>
    <div
      class="styled-select"
      tabindex="0" @blur="(event) => checkBlur(event)"
      @click.prevent="optionsShown = !optionsShown;" @keypress.enter.prevent="optionsShown = !optionsShown">
      {{ currentChoice ? choices[currentChoice] : choices[0] }}
    </div>
    <div :id="`options-${stepId}`" class="styled-options" :class="{ 'styled-options-hidden': !optionsShown }">
      <div
        v-for="choice in choices" :key="choices.indexOf(choice)" class="styled-option" tabindex="0" @blur="(event) => checkBlur(event)"
        @click.prevent="changeChoice(choice);"
        @keypress.enter.prevent="changeChoice(choice);">
        {{ choice }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'StyledSelect',
  props: {
    choices: {
      type: Object as PropType<string[]>,
      required: true,
    },
    currentChoice: {
      type: Number,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
  emits: ['on-change'],
  data() {
    return {
      optionsShown: false,
    };
  },
  methods: {
    changeChoice(choice: string): void {
      this.optionsShown = false;
      const currentChoice = this.choices.indexOf(choice);
      if (this.currentChoice !== currentChoice) {
        this.$emit('on-change', currentChoice);
      }
    },

    checkBlur(event: FocusEvent): void {
      const optionsElement = document.querySelector(`#options-${this.stepId}`);
      if (optionsElement) {
        const element = [...optionsElement.childNodes];
        this.optionsShown = (event.relatedTarget) !== null && element.includes((event.relatedTarget as ChildNode));
      } else {
        this.optionsShown = false;
      }
    },
  },
});

</script>
<style scoped lang="scss">
.styled-options-hidden {
  display: none;
}

.styled-select {
  align-items: center;
  color: #1a225f;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding-left: 10px;
  position: relative;
  user-select: none;
  width: 100%;
}

.styled-options {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .075);
  left: 0;
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 99;
}

.styled-option {
  background-color: #fff;
  color: #1a225f;
  padding: 10px 5px;

  &:hover {
    background-color: #9e99d81a;
  }
}
</style>
