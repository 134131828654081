export interface RoutesNames {
  home: string,
  register: string;
  registerStep1: string;
  registerStep2: string;
  registerSuccess: string;
  login: string;
  lostPassword: string;
  lostPasswordConfirm: string;
  dashboard: string;
  jobs: string;
  brand: string;
  brandPreview: string;
  publicResume: string;
  resumes: string;
  account: string;
  addresses: string;
  candidateProfile: string;
  addNewAddress: string;
  myQrCodes: string;
  pageNotFound: string;
  addressComplete: string;
  reset: string;
}

const routesNames: Readonly<RoutesNames> = {
  home: 'Home',
  register: 'RegisterView',
  registerStep1: 'RegisterStep1',
  registerStep2: 'RegisterStep2',
  registerSuccess: 'RegisterSuccess',
  login: 'LoginView',
  lostPassword: 'LostPassword',
  lostPasswordConfirm: 'LostPasswordConfirm',
  dashboard: 'DashboardView',
  jobs: 'JobsView',
  brand: 'BrandView',
  brandPreview: 'BrandPreview',
  publicResume: 'PublicResume',
  resumes: 'ResumeView',
  account: 'AccountView',
  addresses: 'AddressesView',
  candidateProfile: 'CandidateProfile',
  addNewAddress: 'AddNewAddress',
  myQrCodes: 'MyQrCodes',
  pageNotFound: 'PageNotFound',
  addressComplete: 'AddressComplete',
  reset: 'ResetView',
};

export default routesNames;
