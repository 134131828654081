<template>
  <div v-if="user" class="notification fw-700">
    <router-link :to="{ name: routeNames.account }" class="notification-link">
      <div class="user-name d-none d-lg-block">{{ user.recruiter.manager }}</div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'NotificationView',
  computed: {
    ...mapState('recruiter', [
      'user',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
    managerNameFirstLetters(): string {
      return this.user.recruiter.manager ? this.user.recruiter.manager.split(' ').map((word: string) => word[0]).join('') : '';
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.notification {
  color: $white;
  font-family: $headings-line-height;
}

.notification-link {
  color: currentColor;
  text-decoration: none;
}

.user-name {
  margin-right: 10px;
}

.user-avatar {
  background-color: $light;
  border-radius: 50%;
  font-size: 12px;
  height: 32px;
  line-height: 12px;
  width: 32px;

  img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
}
</style>
