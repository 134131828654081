<template>
  <div v-if="subscription" :id="`price-${price.id}`" class="subscription-card" :class="{ 'is-checked': isChecked }">
    <div class="subscription-card-wrapper">
      <input
        :id="`radio-price-${price.id}`"
        :checked="isChecked"
        name="subscription"
        type="radio"
        class="subscription-card-wrapper-radio" @change.prevent="setSubscriptionAction()">

      <label :for="`radio-price-${price.id}`" class="subscription-card-content">
        <div class="subscription-card-title">
          {{ cardTitle }}
        </div>
        <div class="subscription-card-price-text">
          <strong>
            {{ Math.round(pricePerMonth) / 100 }}€
            {{ $t('price.without_taxes') }} {{ $t('components.subscription_card.per_month') }}
          </strong>
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'vuex';

import Price from '../interfaces/price';
import Subscription from '../interfaces/subscription';

export default defineComponent({
  name: 'SubscriptionCard',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true,
    },
    price: {
      type: Object as PropType<Price>,
      required: true,
    },
    newUser: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  emits: ['chooseSubscription'],
  data() {
    return {
      maxPricePerYear: 2000 * 12,
    };
  },
  computed: {
    ...mapState('register', {
      selectedPrice: 'price',
    }),
    isChecked(): boolean {
      if (!this.newUser) {
        return false;
      }
      return this.selectedPrice
      && this.price.id === this.selectedPrice.id;
    },
    pricePerMonth(): number {
      let pricePerMonth = 0;

      if (this.price.recurring && this.intervalInMonth > 0) {
        pricePerMonth = this.price.unitAmount / this.intervalInMonth;
      }

      return pricePerMonth;
    },

    pricePerSemester(): number {
      return this.pricePerMonth * 6;
    },

    intervalInMonth(): number {
      let intervalInMonth = 0;

      if (this.price.recurring) {
        if (this.price.recurring.interval === 'month') {
          intervalInMonth = this.price.recurring.intervalCount;
        } else if (this.price.recurring.interval === 'year') {
          intervalInMonth = this.price.recurring.intervalCount * 12;
        }
      }

      return intervalInMonth;
    },

    totalReduction(): number {
      let totalReduction = 0;

      if (this.price.pricePerYear < this.maxPricePerYear) {
        totalReduction = this.maxPricePerYear - this.price.pricePerYear;
      }

      return totalReduction;
    },

    totalReductionAsPercentage(): number {
      let totalReductionAsPercentage = 0;

      if (this.price.pricePerYear > 0) {
        totalReductionAsPercentage = Math.round(((100 * this.maxPricePerYear) / this.price.pricePerYear) - 100);
      }

      return totalReductionAsPercentage;
    },

    cardTitle(): string {
      if (!this.maxPricePerYear) return '';

      switch (this.intervalInMonth) {
        case 1: {
          return this.$t('components.subscription_card.paying_duration_1_title');
        }
        case 6: {
          return this.$t('components.subscription_card.paying_duration_6_title');
        }
        case 12: {
          return this.$t('components.subscription_card.paying_duration_12_title');
        }
        default: {
          return `${this.intervalInMonth} ${this.$t('components.subscription_card.month')}`;
        }
      }
    },
  },
  methods: {
    ...mapActions('register', [
      'setPrice',
      'setSubscription',
    ]),
    setSubscriptionAction(): void {
      if (!this.newUser) {
        this.$emit('chooseSubscription');
        return;
      }
      this.setPrice(this.price);
      this.setSubscription(this.subscription);
    },
  },
});

</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../scss/config/config';

.subscription-card {
  border-radius: math.div($grid-gutter-width, 2);
  color: $dark-blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px 10px;

  &.is-checked {
    box-shadow: 0 0 math.div($grid-gutter-width, 2) 0 rgba(0, 0, 0, .15);
  }
}

.subscription-card-wrapper {
  align-items: center;
  display: grid;
  gap: $spacer;
  grid-template-columns: 16px 1fr;
}

.subscription-card-subtitle,
.subscription-item-text {
  white-space: pre-wrap;
}

.subscription-card-content {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin: 0;
}

.icon {
  margin-right: 18px;
}

.subscription-card-franchises {
  font-size: 26px;
  line-height: $grid-gutter-width;
  margin-top: math.div($grid-gutter-width, 2);
}
</style>
