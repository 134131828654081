<template>
  <nav class="app-navigation">
    <ul class="list-unstyled app-navigation-list">
      <li v-if="isPremium">
        <router-link :to="{ name: routeNames.dashboard }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite icon="dashboard" />
          {{ $t('components.app_navigation.home') }}
        </router-link>
      </li>
      <li v-if="isPremium">
        <router-link :to="{ name: routeNames.resumes }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite icon="resume" />
          {{ $t('components.app_navigation.resumes') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: routeNames.jobs }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite icon="job" />
          {{ $t('components.app_navigation.jobs') }}
        </router-link>
      </li>
      <li v-if="isPremium">
        <router-link :to="{ name: routeNames.brand }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite color="#fff" icon="brand" />
          {{ $t('components.app_navigation.brand') }}
        </router-link>
      </li>
      <li v-if="user && (user.recruiter.displayQrCodePage || !isPremium)">
        <router-link :to="{ name: routeNames.myQrCodes }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite icon="qrcode-small" />
          {{ $t('components.app_navigation.my_qr_codes') }}
        </router-link>
      </li>
      <li v-if="isPremium">
        <router-link :to="{ name: routeNames.addresses }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite icon="marker" />
          {{ $t('components.app_navigation.addresses') }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: routeNames.account }" class="app-navigation-link" @click.prevent="closeMenu()">
          <v-sprite icon="account" />
          {{ $t('components.app_navigation.account') }}
        </router-link>
      </li>
      <li>
        <a href="" class="app-navigation-link" @click.prevent="$emit('openModal')" @keydown.enter.prevent="$emit('openModal')">
          <v-sprite icon="logout" />
          {{ $t('components.app_navigation.logout') }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import routesNames, { RoutesNames } from '../router/route-names';

export default defineComponent({
  name: 'AppNavigation',
  emits: ['close-menu', 'openModal'],

  computed: {
    ...mapState('recruiter', [
      'user',
    ]),
    ...mapGetters('recruiter', [
      'isPremium',
    ]),
    routeNames(): RoutesNames {
      return routesNames;
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'logout',
      'disableGlobal',
    ]),
    ...mapActions('register', [
      'reset',
    ]),
    closeMenu(): void {
      this.$emit('close-menu');
    },
    localDisableGlobal(): void {
      this.closeMenu();
    },
  },
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-navigation {
  display: none;

  @include mq($from: desktop) {
    display: block;
  }

  @media print {
    display: none;
  }
}

.app-navigation-link {
  align-items: center;
  color: $white;
  display: flex;
  gap: 10px;
  margin-bottom: 2px;
  padding: 15px 20px;
  text-decoration: none;

  svg {
    width: 28px;
  }

  &.router-link-active {
    background-color: $pink;
    border-radius: 10px;
  }
}

.offcanvas-navigation .app-navigation-link {
  width: 243px;

  &.router-link-active {
    background-color: $pink;
  }
}
</style>
