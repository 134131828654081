<template>
  <svg :class="`svg-${icon} svg-${icon}-dims`" :style="{ color }">
    <use :href="`${spritePath}#${icon}`" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SpriteView',
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#001659',
    },
  },
  data() {
    return {
      spritePath: localStorage.getItem('spritePath'),
    };
  },
});
</script>
