<template>
  <div class="app-header">
    <div class="d-flex align-items-center d-lg-none">
      <v-app-navigation-responsive @open-modal="$emit('openModal')" />
    </div>
    <v-store-switch />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppHeader',
  emits: ['openModal'],
});
</script>

<style scoped lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.app-header {
  display: grid;
  gap: 10px;
  grid-template-columns: 27px auto;
  padding: $spacer 0;

  @include mq($from: desktop) {
    grid-template-columns: auto;
    padding: 35px 0;
  }

  @media print {
    display: none;
  }
}

.logo {
  color: $white;
  padding-left: 20px;
}

.notification {
  display: flex;
  justify-content: flex-end;
}
</style>
